import { ArrowBack } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Language from "./Languages";
import "./registermanager.css";

export default function OldJoin() {
  const navigate = useNavigate();

  const handleLogin = () => {
    // navigate("/home");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    toast.error(
      <div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "600",
            margin: "0 0 12px 0",
          }}
        >
          We're unable to process your request at the moment. Please try again
          later.
        </p>
      </div>,

      {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      }
    );
    // const data = new FormData(event.currentTarget);
    // console.log(server);
    // console.log({
    //   email: data.get("email"),
    //   password: data.get("password"),
    //   initial_investment: data.get("initial_investment"),
    // });

    // try {
    //   const response = await axios.post(`${API_URL}`, {
    //     password: data.get("password"),
    //     email: data.get("email"),
    //     account: data.get("mt-account"),
    //     name_PAMM_account: data.get("name_PAMM_account"),
    //     description: data.get("description"),
    //     server: server,
    //     mood: "register",
    //   });

    //   //   setMessage(response.data.message);
    //   if (response.data.success === true) {
    //     toast.success(response.data.message, {
    //       position: "bottom-right",
    //       autoClose: true,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: false,
    //       progress: undefined,
    //       theme: "colored",
    //     });
    //     navigate("/");
    //   } else {
    //     toast.error(response.data.message, {
    //       position: "bottom-right",
    //       autoClose: true,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: false,
    //       progress: undefined,
    //       theme: "colored",
    //     });
    //   }
    // } catch (error) {
    //   // console.error("Error signing up:", error);
    //   toast.error(error, {
    //     position: "bottom-right",
    //     autoClose: true,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: false,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }
  };

  const [server, setServer] = React.useState("zenfinexGlobal-live");
  // const [mtAccount, setMtAccount] = React.useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setServer(event.target.value);
  };

  const handleChange2 = (event) => {
    const inputValue = event.target.value;
    // setMtAccount(inputValue);
    setError(inputValue.trim() === "");
  };

  // const [open, setOpen] = React.useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };

  // const LightTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "rgba(0, 0, 0, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // }));

  // const [state, setState] = React.useState({
  //   gilad: true,
  //   jason: false,
  //   antoine: false,
  // });

  // const handleChange3 = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  // const { gilad, jason, antoine } = state;
  // const error1 = [gilad, jason, antoine].filter((v) => v).length !== 2;

  return (
    <>
      <div style={{ height: "100%", width: "100%" }} className="">
        <ToastContainer />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            margin: "0.7rem 2rem",
          }}
        >
          <Language />
        </div>
        <Container component="main" style={{ maxWidth: "600px", padding: 0 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/taurex_logo.png"
              alt="logo"
              style={{ width: "200px", height: "auto", marginTop: "2rem" }}
            />
          </div>
          <p
            style={{
              marginTop: "3rem",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                font: "400 24px/32px Nunito,sans-serif",
                opacity: "0.8",
              }}
            >
              PAMM - Taurex
              <span style={{ opacity: ".5", paddingLeft: "10px" }}>
                Money Manager
              </span>
            </span>
          </p>
          <Box
            sx={{
              boxShadow: 2,
              borderRadius: 1,
              px: 2,
              py: 2,
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              fontWeight: "100",
              margin: "20px auto",
              maxWidth: "60%",
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Box sx={{ minWidth: 120 }}>
                <p
                  style={{
                    display: "flex",
                    margin: 0,
                    fontWeight: "100",
                  }}
                >
                  Registration
                </p>
                <FormControl
                  margin="normal"
                  fullWidth
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "15.75px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Server</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={server}
                    label="Server"
                    onChange={handleChange}
                  >
                    <MenuItem value={"zenfinexGlobal-live"}>
                      zenfinexGlobal-live
                    </MenuItem>
                    <MenuItem value={"zenfinexGlobal-live2"}>
                      zenfinexGlobal-live2
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                id="mt-account"
                label="Username"
                name="mt-account"
                autoFocus
              />

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                name="name_PAMM_account"
                label="Name of the PAMM account"
                type="text"
                id="name_PAMM_account"
              />

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                name="description"
                label="Description"
                type="text"
                id="description"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleLogin}
                style={{
                  backgroundColor: "#37474f",
                  height: "52px",
                  textTransform: "none",
                }}
                sx={{ mt: 3 }}
              >
                <DoneIcon style={{ color: "white", fontWeight: "400" }} />
                <span style={{ paddingLeft: "10px" }}>Register</span>
              </Button>
            </Box>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              flexDirection: "column",
            }}
          >
            <Button
              style={{
                textTransform: "capitalize",
                color: "black",
                border: "1px solid  #E6E6E6",
                justifyContent: "left",

                marginLeft: "98px",
              }}
              variant="outlined"
              onClick={() => navigate("/app/auth/manager")}
            >
              <ArrowBack fontSize="small" style={{ marginRight: "5px" }} />
              Back to Sign in
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}
