import { ArrowBack } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Language from "./Languages";
import "./oldJoin.css";

export default function OldJoin() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const handleLogin = () => {
  //   // navigate("/home");
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   const data = new FormData(event.currentTarget);
  //   try {
  //     const response = await axios.post(`${API_URL}`, {
  //       password: data.get("password"),
  //       email: data.get("email"),
  //       full_name: data.get("full_name"),
  //       account: data.get("mt-account"),
  //       initial_investment: data.get("initial_investment"),
  //       server: server,
  //       mood: "signup",
  //     });
  //     setLoading(false);
  //     //   setMessage(response.data.message);
  //     if (response.data.success === true) {
  //       toast.success(response.data.message, {
  //         position: "bottom-right",
  //         autoClose: true,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       navigate("/app/auth/investor");
  //     } else {
  //       toast.error(response.data.message, {
  //         position: "bottom-right",
  //         autoClose: true,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   } catch (error) {
  //     // console.error("Error signing up:", error);
  //     toast.error(error, {
  //       position: "bottom-right",
  //       autoClose: true,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   }
  // };

  // const [server, setServer] = React.useState("zenfinexGlobal-live");
  // const [mtAccount, setMtAccount] = React.useState("");
  // const [error, setError] = useState(false);

  // const handleChange = (event) => {
  //   setServer(event.target.value);
  // };

  // const handleChange2 = (event) => {
  //   const inputValue = event.target.value;
  //   setMtAccount(inputValue);
  //   setError(inputValue.trim() === "");
  // };

  // const [open, setOpen] = React.useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };

  // const LightTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "rgba(0, 0, 0, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // }));

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  // const handleChange3 = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  const { gilad, jason, antoine } = state;
  const error1 = [gilad, jason, antoine].filter((v) => v).length !== 2;

  return (
    <>
      <div style={{ height: "100%", width: "100%" }} className="">
        <ToastContainer />
        <div
          className="investorHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0.7rem 2rem",
          }}
        >
          <Button
            style={{
              textTransform: "capitalize",
              color: "black",
              border: "1px solid  #E6E6E6",
            }}
            variant="outlined"
            onClick={() => navigate("/registerinvestor")}
          >
            <ArrowBack fontSize="small" style={{ marginRight: "5px" }} />
            Back to Sign in
          </Button>
          <Language />
        </div>
        <Container component="main" style={{ maxWidth: "600px", padding: 0 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/taurex_logo.png"
              alt="logo"
              style={{ width: "200px", height: "auto", marginTop: "2rem" }}
            />
          </div>
          <p
            className="login-heading"
            style={{
              marginTop: "3rem",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                font: "400 22px/32px Nunito,sans-serif",
                color: "rgb(0 0 0 / 87%)",
                fontWeight: "600",
                fontsize: "22px",
                display: "block",
              }}
            >
              PAMM - Taurex
              <span
                style={{
                  opacity: ".5",
                  paddingLeft: "10px",
                  color: "#6c757d",
                  fontWeight: "700",
                }}
              >
                Investor Registration
              </span>
            </span>
          </p>
        </Container>
        <p style={{ paddingBottom: "40px" }}></p>
      </div>
    </>
  );
}
