import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import axios from "axios";

import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResponsiveAppBar2() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const Logout = async () => {
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "logout",
      });

      if (response.data.success === true) {
        // alert("Login Successful");
        sessionStorage.clear();
        setTimeout(() => {
          navigate("/app/auth");
        }, 1000);
      } else {
        toast.error(
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
                margin: "0 0 12px 0",
              }}
            >
              {response.data.message}
            </p>
            <span
              style={{
                fontSize: "14px",
                display: "block",
                lineHeight: "0",
                marginBottom: "5px",
              }}
            >
              Invalid login/password
            </span>
          </div>,

          {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            style: {
              backgroundColor: "#bd362f", // Set your desired background color here
            },
          }
        );
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      });
      console.error("Error signing up:", error);
    }
  };

  const handleButtonClick = () => {
    // Implement logic to open a new page
    alert("You clicked the button!");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div style={{ backgroundColor: "black", marginBottom: "3rem" }}>
        <ToastContainer />
        <AppBar
          position="static"
          sx={{ backgroundColor: "black", maxWidth: "90%", margin: "auto" }}
        >
          <Container
            className="appbar-container"
            style={{ padding: "0 20px", maxWidth: "100%" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              <Link to="/app/admin/dashboard">
                <img
                  src="/assets/taurex_logo.png"
                  className="taurex-logo"
                  alt="logo"
                  style={{
                    width: "100px",
                    height: "20px",
                    marginRight: "70px",
                    marginLeft: "40px",
                  }}
                />
              </Link>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <NavLink
                  to="/app/admin/dashboard"
                  style={{ textDecoration: "none" }}
                  isActive={(match, location) => {
                    // Check if the current location matches the specified route
                    return location.pathname === "/app/admin/dashboard";
                  }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    History
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/users"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    Users
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/symbols"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    Symbols
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/positions"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    positions
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/demo-positions"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    user positions
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/performance-fees"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    Performance Fees
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/investor-registration"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    Investor Registration
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/settings"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    Setting
                  </Button>
                </NavLink>
                <NavLink
                  to="/app/admin/support"
                  style={{ textDecoration: "none" }}
                  activeStyle={{
                    fontWeight: "bold", // or any other styles you want to apply for the active link
                  }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    Support Ticket
                  </Button>
                </NavLink>
              </Box>
              <Box className="hammer-menu" sx={{ flexGrow: 1 }}>
                <Button
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{ color: "white" }}
                >
                  <MenuIcon />
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem component={Link} to="/app/admin/dashboard">
                    Dashboard
                  </MenuItem>
                  <MenuItem component={Link} to="/app/admin/users">
                    Users
                  </MenuItem>
                  <MenuItem component={Link} to="/app/admin/symbols">
                    Symbols
                  </MenuItem>
                  <MenuItem component={Link} to="/app/admin/positions">
                    Positions
                  </MenuItem>
                  <MenuItem component={Link} to="/app/admin/demo-positions">
                    Test user positions
                  </MenuItem>
                  <MenuItem component={Link} to="/app/admin/performance-fees">
                    Performance Fees
                  </MenuItem>
                  <MenuItem component={Link} to="/app/admin/settings">
                    Setting
                  </MenuItem>
                  <MenuItem component={Link} to="/app/admin/support">
                    Support Ticket
                  </MenuItem>
                </Menu>
              </Box>
              <Box style={{ marginLeft: "auto" }}>
                {/* <Tooltip title="Open settings"> */}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <IconButton
                    className="appbar-buttons-container"
                    style={{
                      display: "block",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  ></IconButton>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircleOutlinedIcon
                      style={{
                        color: "#999999",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    />
                    <p
                      className="investor-name"
                      style={{
                        color: "#999999",
                        fontSize: "15px",
                        marginLeft: "5px",
                        alignItems: "center",
                        display: "flex",
                        fontWeight: "500",
                        ...(isMobile && {
                          display: "none",
                        }),
                      }}
                    >
                      {userData.username}
                    </p>
                  </IconButton>
                </div>
                {/* </Tooltip> */}
                <Menu
                  sx={{ mt: "45px", mr: "200px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => Logout()}>
                    <LogoutRoundedIcon style={{ marginRight: "11px" }} />
                    <Typography style={{ fontSize: "13px" }} textAlign="center">
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          </Container>
        </AppBar>
      </div>
    </>
  );
}

export default ResponsiveAppBar2;
