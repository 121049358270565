import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import copy from "copy-to-clipboard";
import React, { useState } from "react";

const CopyToClipboardIcon = ({ text }) => {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  console.log(text);

  const handleCopyClick = () => {
    copy(text);
    setShowCopiedTooltip(true);
    setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 2000); // Hide tooltip after 2 seconds
  };

  return (
    <Tooltip title={showCopiedTooltip ? "Copied!" : "Copy"} arrow>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleCopyClick}
        style={{ marginLeft: "5px" }}
      >
        <ContentCopyOutlinedIcon />
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboardIcon;
