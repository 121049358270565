// SettingsTable.js
import { Card, CardContent, Switch, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ResponsiveAppBar from "./AppBar2.js";

function SettingsTable() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    // Fetch settings from the server on component mount
    getSettings();
  }, []);

  const getSettings = async () => {
    try {
      const response = await axios.post(API_URL, {
        mood: "getSettings",
      });

      // Ensure that the response data is an array before setting the state
      if (Array.isArray(response.data.data)) {
        setSettings(response.data.data);
      } else {
        console.error("Invalid data format received:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleToggle = async (id, newValue) => {
    // Send a request to the server to update the setting value
    // You will need to implement the API endpoint in your PHP backend
    await axios.post(API_URL, {
      mood: "cornUpdate",
      id: id,
      value: newValue ? 1 : 0,
    });

    // Update the state with the updated setting
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.id === id ? { ...setting, value: newValue ? 1 : 0 } : setting
      )
    );
  };

  return (
    <>
      <ResponsiveAppBar />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {settings.map((setting) => (
          <Card key={setting.id} style={{ margin: "10px", minWidth: "200px" }}>
            <CardContent>
              <Typography variant="h6">{setting.name}</Typography>
              <Typography variant="body1">ID: {setting.id}</Typography>
              <Typography variant="body1">Value: {setting.value}</Typography>
              <Typography variant="body1">Toggle:</Typography>
              <Switch
                checked={parseInt(setting.value) === 1}
                onChange={(event) =>
                  handleToggle(setting.id, event.target.checked)
                }
              />
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );
}

export default SettingsTable;
