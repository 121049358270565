import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReplayIcon from "@mui/icons-material/Replay";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Select from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import axios from "axios";
import * as React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Admin/supportTicket.css";
import PageLoader from "../PageLoader.js";
import ResponsiveAppBar from "./AppBar2.js";

export default function History() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [deleteUserId, setDeleteUserId] = React.useState(null);
  const [blockUserId, setBlockUserId] = React.useState(null);
  const [blockUserStatus, setBlockUserStatus] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openBlockDialog, setOpenBlockDialog] = React.useState(false);
  const [statusMap, setStatusMap] = React.useState({});
  // const [walletUserId, setWalletUserId] = React.useState(null);
  // const [openWalletIdResetDialog, setOpenWalletIdResetDialog] = React.useState(false);

  const theme = useTheme();

  const handleDeleteUser = (userId) => {
    setDeleteUserId(userId);
    setOpenDeleteDialog(true);
  };

  // const handleBlockUser = (userId) => {
  //   setBlockUserId(userId);
  //   setOpenBlockDialog(true);
  // };

  const handleConfirmDelete = async () => {
    // Implement API call to delete user with deleteUserId
    // console.log("Deleting user with ID:", deleteUserId);
    try {
      const response = await axios.post(API_URL, {
        mood: "deleteSupportTickets",
        id: deleteUserId,
      });
      // Set the fetched data to the state
      if (response.data.success === true) {
        toast.success("User deleted successfully");
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // Close the dialog
    setOpenDeleteDialog(false);
  };

  const handleConfirmBlock = async () => {
    // Implement API call to block/unblock user with blockUserId
    console.log(
      "Blocking/Unblocking user with ID:",
      blockUserId + blockUserStatus
    );

    // Close the dialog
    setOpenBlockDialog(false);
    try {
      const response = await axios.post(API_URL, {
        mood: "BlockUser",
        id: blockUserId,
        blocked: blockUserStatus,
      });
      console.log(response);
      // Set the fetched data to the state
      if (response.data.success === true) {
        toast.success("User Block status change successfully");
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading
    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
      fetchData(page, rowsPerPage);
    }, 2000);
  };

  const fetchData = async (page, rowsPerPage) => {
    try {
      const response = await axios.post(API_URL, {
        mood: "getSupportTickets",
        page: page,
        rowsPerPage: rowsPerPage,
      });

      // Set the fetched data to the state
      // setData(response.data.data);
        response.data.data != undefined ? setData(response.data.data) : setData([]);
      setTotalRows(response.data.totalRows);

      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    handleStartLoading();
  }, []);

  const handleChangePage = (event, newPage) => {
    var pages = newPage + 1;
    setPage(pages);
    fetchData(pages, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPage(1);
    setRowsPerPage(newRowsPerPage);

    fetchData(1, newRowsPerPage);
  };
  const handleBlockUser = (userId, newBlockedStatus) => {
    setBlockUserId(userId);
    setBlockUserStatus(newBlockedStatus === true ? "Y" : "N");
    setOpenBlockDialog(true);
  };


  // Function to handle status change
  const handleChangeStatus = (id, event) => {
    const newStatusMap = { ...statusMap };
    newStatusMap[id] = event.target.value;
    setStatusMap(newStatusMap);
  };

  // Function to update status using Axios
  const handleUpdateStatus = (id) => {
    const newStatus = statusMap[id];
    axios.post(API_URL, {
      mood: 'supportStatus',
      id: id,
      status: newStatus
    })
    .then(response => {
      // Handle success (optional)
       toast.success('Status updated successfully:', response.data);
      // console.log('Status updated successfully:', response.data);
      // You may want to update your data array or fetch new data here
    })
    .catch(error => {
      // Handle error (optional)
      console.error('Error updating status:', error);
    });
  };


  // const handleOpenConfirmationDialog = (userId) => {
  //   setWalletUserId(userId);
  //   setOpenWalletIdResetDialog(true);
  // };

  // const handleWalletReset = async () => {
  //   // Implement API call to reset wallet ID with walletUserId
  //   console.log("Resetting wallet ID for user with ID:", walletUserId);

  //   // Close the dialog
  //   setOpenWalletIdResetDialog(false);
  //   try {
  //     const response = await axios.post(API_URL, {
  //       mood: "resetWalletId",
  //       id: walletUserId,
  //     });
  //     console.log(response);
  //     // Set the fetched data to the state
  //     if (response.data.success === true) {
  //       toast.success("User wallet ID reset successfully");
  //       fetchData();
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  return (
    <>
      <ResponsiveAppBar />
      <ToastContainer />
      <div
        className="dashboard history-dashboard"
        style={{
          maxWidth: "90%",
          margin: "0 auto",
        }}
      >
        <Typography className="history-heading" variant="h5">
          Support Ticket
        </Typography>

        <div className="table history">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading(true)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <TableContainer className="transaction-table" component={Paper}>
            {isLoading ? (
              <PageLoader />
            ) : (
              <>
                <Box className="transction-table-inner">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="table-haeding-font"
                            style={{ fontSize: "12px" }}
                            align="left"
                        >
                          #
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          User Name
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Title
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Description
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Created At
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {data.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            <Typography variant="subtitle1">Record not found</Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                      data.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            className="table-haeding-font"
                            style={{ fontSize: "12px" }}
                            component="th"
                            scope="row"
                            align="left"
                          >
                            #{row.id}
                          </TableCell>
                          <TableCell
                            className="table-haeding-font"
                            style={{ fontSize: "12px" }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {row.full_name ?? '--'}
                          </TableCell>
                          {/* <TableCell align="right">{row.requestType}</TableCell> */}
                          <TableCell className="history-font" align="center">
                            {row.title}
                          </TableCell>
                          <TableCell className="history-font" align="center">
                            <div style={{ width: "100%", maxWidth: '200px', display: "inline-block"}}>
                            {row.description}
                            </div>
                          </TableCell>
                          
                          <TableCell className="history-font" align="center">
                            {row.created_at ?? ''}
                          </TableCell>
                          <TableCell className="history-font" align="center">
                            <FormControl>
                              <Select
                                value={statusMap[row.id] || row.status}
                                onChange={(e) => handleChangeStatus(row.id, e)}
                              >
                                <MenuItem value="P">Pending</MenuItem>
                                <MenuItem value="A">Approved</MenuItem>
                                <MenuItem value="R">Rejected</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <Button
                                className="delete-button"
                              onClick={() => handleDeleteUser(row.id)}
                              variant="contained"
                              color="secondary" // Using secondary color for delete
                              style={{ marginRight: theme.spacing(1) }}
                              startIcon={<DeleteIcon style={{ marginRight: '0px'}} />} // Add DeleteIcon as start icon
                            >
                              
                            </Button>
                            {/* <Link to={`/app/admin/users/${row.id}`}>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<VisibilityIcon />}
                              >
                                View
                              </Button>
                            </Link> */}
                              <Button
                                 className="update-button"
                              variant="contained"
                                color="primary"
                                startIcon={<RefreshIcon />}
                              onClick={() => handleUpdateStatus(row.id)}
                            >
                             
                              </Button>
                              </div>
                            {/* Uncomment the lines below if you have a WarningIcon component */}
                            {/* <Button
                              variant="contained"
                              color="warning"
                              onClick={() => handleOpenConfirmationDialog(row.id)}
                              startIcon={<WarningIcon />}
                            >
                              Warning
                            </Button> */}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                    </TableBody>
                  </Table>
                </Box>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </TableContainer>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle className="table-haeding-font">
          Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="table-haeding-font">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button fontWeight="600" onClick={() => setOpenDeleteDialog(false)}>
            Cancel
          </Button>
          <Button fontWeight="600" onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Block/Unblock Confirmation Dialog */}
      <Dialog open={openBlockDialog} onClose={() => setOpenBlockDialog(false)}>
        <DialogTitle>Block/Unblock Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to block/unblock this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBlockDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmBlock} color="primary">
            Block/Unblock
          </Button>
        </DialogActions>
      </Dialog>

      {/* wallet reset Confirmation Dialog */}
      {/* <Dialog
        open={openWalletIdResetDialog}
        onClose={() => setOpenWalletIdResetDialog(false)}
      >
        <DialogTitle>Reset Wallet ID</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset the user\'s wallet ID?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenWalletIdResetDialog(false)}>No</Button>
          <Button onClick={handleWalletReset} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
