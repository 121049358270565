import { Modal as BaseModal } from "@mui/base/Modal";
import { AccountCircle, AttachMoney } from "@mui/icons-material";
import { Button, Divider, InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { css, styled } from "@mui/system";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function DepositModal({ open, setOpen }) {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const [totalAmount, setTotalAmount] = React.useState(0);

  React.useEffect(() => {
    // Assuming API_URL is defined somewhere in your code

    axios
      .post(API_URL, {
        mood: "getAccountDetails",
        userId: userData.id,
      })
      .then((response) => {
        console.log(response.data);
        setTotalAmount(response.data.data.totalAmount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleOpen = () => {
    if (open === "editModel") return true;
    else {
      return false;
    }
  };
  const handleClose = () => {
    setOpen("");
  };
  const navigate = useNavigate();
  const [amount, setAmount] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleChange2 = (event) => {
    const inputValue = event.target.value;
    setAmount(inputValue);
    setError(inputValue.trim() === "");
  };

  const depositSubmit = async () => {
    if (amount < 1) {
      toast.error("Value must be > 0 but was 0", {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    try {
      const response = await axios.post(`${API_URL}`, {
        email: userData?.email,
        server: userData?.server,
        username: userData?.username,
        userId: userData?.id,
        amount: amount,
        mood: "deposit",
      });

      //   setMessage(response.data.message);
      if (response.data.success === true) {
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          navigate(`/app/payment?amount=${amount}`);
        }, 2000);
      } else {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      // console.error("Error signing up:", error);
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const top100Films = [
    { label: "None" },
    { label: "Daily" },
    { label: "Weekly" },
    { label: "Monthly" },
  ];
  return (
    <div>
      <ToastContainer />
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={handleOpen()}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent
          style={{ width: "291", height: "263", paddingBottom: "10px" }}
        >
          <h4
            id="unstyled-modal-title"
            className="modal-titled"
            style={{
              font: "normal  bold 12px/32px",
              letterSpacing: "normal",
              margin: "0 0 16px",
            }}
          >
            Investment Settings
            <br />
            <small
              style={{
                color: "#6c757d",
                opacity: ".8",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {userData?.full_name}
            </small>
          </h4>
          <div>
            <div>
              <h4
                id="unstyled-modal-title"
                className="modal-title"
                style={{
                  fontSize: "20px",
                  marginBottom: "24px",
                  letterSpacing: "1px",
                }}
              >
                Reports
              </h4>
            </div>

            <div style={{ margin: "0 0 30px" }}>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                size="small"
                style={{ margin: "0" }}
              >
                <InputLabel id="demo-select-small-label">
                  Periodic email report frequency
                </InputLabel>
                <Select
                  style={{
                    width: "214px",
                    height: "54px",
                    borderFocus: "border: 0.5px solid #000",
                  }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Periodic email report frequency"
                  value="1"
                  auto
                >
                  <MenuItem>None</MenuItem>
                  <MenuItem>Daily</MenuItem>
                  <MenuItem value="1">Weekly</MenuItem>
                  <MenuItem>Monthly</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                textTransform: "capitalize",
                color: "white",
                border: "1px solid  #E6E6E6",
                background: "#37474F",
                marginRight: "10px",
              }}
              onClick={depositSubmit}
              variant="outlined"
            >
              <SvgIcon>
                {/* credit: plus icon from https://heroicons.com/ */}
                <svg
                  fill="#000000"
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  id="check"
                  data-name="Line Color"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon line-color"
                >
                  <polyline
                    id="primary"
                    points="5 12 10 17 19 8"
                    style={{
                      fill: "none",
                      stroke: "rgb(245 245 245)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 2,
                    }}
                  />
                </svg>
              </SvgIcon>
              Save
            </Button>

            <Button
              style={{
                textTransform: "capitalize",
                color: "black",
                border: "0",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    max-width: 260px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

const TriggerButton = styled("button")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px
        ${theme.palette.mode === "dark" ? blue[300] : blue[200]};
      outline: none;
    }
  `
);
