import React from "react";
// import { useLocation } from "react-router-dom";
import "./RequestPage.css";

const InvestmentReport = () => {
  // const location = useLocation();
  // const data = location.state?.data || {};

  // console.log("check data", data);
  return (
    <div className="report-body">
      <h1>PAMM Investment Report</h1>

      <section>
        <dl className="dl-horizontal">
          <dt className="report-header-info">Investor</dt>
          {/* <dd>
            Bankim Prasad Jana
            <small className="text-muted">#7990</small>
          </dd> */}

          <dt className="report-header-info">Money Manager</dt>
          <dd className="report-header-info">
            PIIP capital
            <small className="text-muted">#066</small>
          </dd>

          <dt className="report-header-info">Investment date</dt>
          {/* <dd>2023.07.28</dd> */}

          <dt className="report-header-info">Trading Interval</dt>
          <dd>
            {/* <div>2023.12.24 - 2023.12.30</div> */}
            <div>{/* <small className="text-muted">1 Weeks</small> */}</div>
          </dd>
        </dl>
      </section>

      <section>
        <h2>
          {/* Summary <small className="text-muted">(2023.12.27 06:16:27)</small> */}
        </h2>

        <dl className="dl-horizontal">
          <dt className="report-header-info">Deposits</dt>
          {/* <dd>500.00 USD</dd> */}

          <dt className="report-header-info">Withdrawals</dt>
          {/* <dd>-563.00 USD</dd> */}

          <dt className="report-header-info">Fees</dt>
          <dd>
            {/* <div>-34.64 USD</div> */}
            <div className="text-muted">
              {/* <small>
                -34.64 USD performance fee
                <br />
              </small> */}
            </div>
          </dd>

          <dt className="report-header-info">Trade Results</dt>
          <dd>
            {/* <div>98.88 USD</div> */}
            <div className="text-muted">
              {/* <small>
                98.88 USD closed <br />
                0.03 USD current trading interval <br />
              </small> */}
            </div>
          </dd>

          <dt className="text-strong profit-text">Net Profit</dt>
          {/* <dd className="text-large text-strong">64.24 USD</dd> */}

          <dt className="text-strong profit-text">Funds</dt>
          {/* <dd className="text-large text-strong">1.24 USD</dd> */}
        </dl>
      </section>

      <h2 className="detaill-header">
        Detailed report{" "}
        {/* <small className="text-muted">(2023.12.01 – 2023.12.27)</small> */}
      </h2>

      <section>
        <h3 className="report-table-heading">Balance operations and fees</h3>
        <table className="pure-table pure-table-horizontal">
          <thead>
            <tr>
              <th class="table-header">Transaction #</th>
              <th class="table-header">Reason</th>
              <th class="table-header">Amount</th>
              <th class="table-header">Time</th>
              <th class="table-header">Operation ID</th>
              <th class="table-header">Comment</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>17480810</td>
              <td>PerformanceFee</td>
              <td className="text-right">-0.16</td>
              <td>2023.12.03 00:15:41</td>
              <td></td>
              <td></td>
            </tr> */}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2" className="text-right total-text">
                Total:
              </td>
              <td className="text-right">
                {/* <small>USD</small> <strong>-0.40</strong> */}
              </td>
              <td colspan="3"></td>
            </tr>
          </tfoot>
        </table>
      </section>

      <section>
        <h3 className="report-table-heading">Open positions</h3>
        <table className="pure-table pure-table-horizontal">
          <thead>
            <tr>
              <th class="table-header">#</th>

              <th class="table-header">Symbol</th>
              <th class="table-header">Direction</th>
              <th className="text-right table-header">Open Price</th>
              <th className="text-right table-header">Close Price</th>

              <th className="text-right table-header">Open time</th>
              <th className="text-right table-header">Close time</th>

              <th className="text-right table-header">1st participation</th>
              <th className="text-right table-header">Rollover count</th>
              <th className="text-right table-header">Profit</th>
            </tr>
          </thead>
          <tbody></tbody>

          <tfoot>
            <tr>
              <td></td>
              <td colspan="4"></td>
              <td colspan="2"></td>
              <td colspan="2" className="text-right total-text">
                Total:
              </td>
              <td className="text-right total-text">
                <small>USD</small> <strong>0.00</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </section>

      <section>
        <h3 className="report-table-heading">Closed positions</h3>
        <table className="pure-table pure-table-horizontal">
          <thead>
            <tr>
              <th class="table-header">#</th>

              <th className="table-header">Symbol</th>
              <th className="table-header">Direction</th>
              <th className="text-right table-header">Open Price</th>
              <th className="text-right table-header">Close Price</th>

              <th className="text-right table-header">Open time</th>
              <th className="text-right table-header">Close time</th>

              <th className="text-right table-header">1st participation</th>
              <th className="text-right table-header">Rollover count</th>
              <th className="text-right table-header">Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td>6421426</td>

              <td>EURAUD-ECN</td>
              <td>Sell</td>
              <td className="text-right">1.61794</td>
              <td className="text-right">1.61647</td>

              <td className="text-right">2023.12.27 02:00:01</td>
              <td className="text-right">2023.12.27 03:56:32</td>

              <td className="text-right">2023.12.27 02:16:07</td>
              <td className="text-right">1</td>
              <td className="text-right">0.01</td> */}
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td></td>
              <td colspan="4"></td>
              <td colspan="2"></td>
              <td colspan="2" className="text-right total-text">
                Total:
              </td>
              <td className="text-right total-text">
                <small>USD</small> <strong>0.78</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};

export default InvestmentReport;
