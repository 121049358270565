import AccountCircle from "@mui/icons-material/AccountCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Language from "./Languages";
import "./loginmanager.css";

export default function LogIn() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsernames] = useState("");
  const [password, setPasswords] = useState("");
  const [message, setMessage] = useState("");
  const [btncolor, btncolors] = useState("#e0e0e0");

  const handleLogin = () => {
    // const data = await response.json();
    //   setMessage(data.message);
    // navigate("/home");
    // navigate("/investments");
  };

  const setUsername = (e) => {
    setUsernames(e);
    if (e !== "" && password !== "") {
      btncolors("#37474f");
    } else {
      btncolors("#e0e0e0");
    }
  };

  const setPassword = (e) => {
    setPasswords(e);

    if (e !== "" && username !== "") {
      btncolors("#37474f");
    } else {
      btncolors("#e0e0e0");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // if (server !== "VTMarkets-Live 4") {
    //   toast.error("Please select VTMarkets-Live 4", {
    //     position: "bottom-right",
    //     autoClose: false,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: false,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    //   return;
    // }
    // const data = new FormData(event.currentTarget);
    //   console.log({
    //     email: data.get("email"),
    //     password: data.get("password"),

    //   });

    try {
      const response = await axios.post(`${API_URL}`, {
        username,
        password,
        mood: "login",
        server: server,
      });
      setLoading(false);
      setMessage(response.data.message);
      if (response.data.success === true) {
        // alert("Login Successful");
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem("userData", JSON.stringify(response.data.data));
        sessionStorage.setItem("token", response.data.token);
        console.log(response.data.token);
        setTimeout(() => {
          navigate("/app/investments");
        }, 1000);
      } else {
        toast.error(
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
                margin: "0 0 12px 0",
              }}
            >
              {response.data.message}
            </p>
            <span
              style={{
                fontSize: "14px",
                display: "block",
                lineHeight: "0",
                marginBottom: "5px",
              }}
            >
              Invalid login/password
            </span>
          </div>,

          {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            style: {
              backgroundColor: "#bd362f", // Set your desired background color here
            },
          }
        );
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      });
      console.error("Error signing up:", error);
    }
  };

  const [server, setServer] = React.useState("zenfinexGlobal-live");
  const [mtAccount, setMtAccount] = React.useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setServer(event.target.value);
  };

  // const handleChange2 = (event) => {
  //     const inputValue = event.target.value;
  //     setMtAccount(inputValue);
  //     setError(inputValue.trim() === '');
  // };

  return (
    <>
      <head>
        <title>Login Page</title>
      </head>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "0.7rem 2rem",
        }}
      >
        <Language />
      </div>
      <Container component="main">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src="/logo.png" alt="logo" style={{ width: "300px", height: "50px", marginTop: "2rem" }} /> */}
          <img
            src="/assets/taurex_logo.png"
            alt="logo"
            style={{ width: "170px", height: "auto", marginTop: "2rem" }}
          />
        </div>
        <p
          className="login-heading"
          style={{
            marginTop: "3rem",
            padding: "0 5px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              font: "400 24px/32px Nunito,sans-serif",
              color: "rgb(0 0 0 / 87%)",
              fontWeight: "600",
              fontsize: "22px",
            }}
          >
            PAMM - Taurex
          </span>
          <span
            style={{
              opacity: ".5",
              paddingLeft: "10px",
              color: "#6c757d",
              fontWeight: "700",
            }}
          >
            Manager
          </span>
        </p>
        <p
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            fontSize: "small",
            opacity: "0.8",
          }}
        ></p>
        <Box
          style={{
            maxWidth: "400px",
            padding: "12px 15px",
            borderRadius: "4px",

            margin: "0 auto",
            boxShadow:
              "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)",
            px: "2",
            py: "2",
            marginTop: "2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Box sx={{ minWidth: 120 }}>
              <FormControl margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-label">Server</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={server}
                  label="Server"
                  name="server"
                  onChange={handleChange}
                >
                  <MenuItem value={"zenfinexGlobal-live"}>
                    zenfinexGlobal-live
                  </MenuItem>
                  <MenuItem value={"zenfinexGlobal-live2"}>
                    zenfinexGlobal-live2
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              margin="normal"
              required
              error={error}
              fullWidth
              onChange={(e) => setUsername(e.target.value)}
              // onChange={handleChange2}
              id="mt-account"
              label="MT Account"
              name="mt-account"
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle style={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              error={error}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              // onChange={handleChange2}
              name="password"
              label="Password"
              type="password"
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleLogin}
              style={{ "background-color": btncolor, height: "52px" }}
              // style={{ backgroundColor: "#37474f", height: "52px" }}
              sx={{ mt: 3 }}
            >
              <ChevronRightIcon
                style={{
                  color: "white",
                  fontSize: "40px",
                  fontWeight: "400",
                }}
              />
            </Button>
          </Box>
        </Box>
        <p
          onClick={() => navigate("/app/auth/register/manager")}
          style={{
            cursor: "pointer",
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            fontSize: "small",
            opacity: "0.8",
          }}
        >
          Money Manager Registration
        </p>
      </Container>
      <ToastContainer />
    </>
  );
}
