import { Modal as BaseModal } from "@mui/base/Modal";
import { SimCardDownload } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { css, styled } from "@mui/system";
import axios from "axios";
// import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";

const ITEM_HEIGHT = 80;
const ITEM_WIDTH = 48;

const options = [
  "This month",
  "This week",
  "Previous month",
  "Previous week",
  "Starting from last month",
  "Starting from last week",
];
export default function ReportModal({ open, setOpen }) {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [anchorOpen, setAchorOpen] = React.useState(false);
  const [includeDetailedResults, setIncludeDetailedResults] =
    React.useState(false);
  const [includeAggregatedResults, setIncludeAggregatedResults] =
    React.useState(true);
  const [includeBalanceTransactions, setIncludeBalanceTransactions] =
    React.useState(true);
  const [fromDate, setFromDate] = React.useState(getStartOfMonth());
  const [toDate, setToDate] = React.useState(getCurrentDate());
  // const navigate = useNavigate();

  React.useEffect(() => {
    // Update toDate whenever fromDate changes
    setToDate(fromDate);
  }, [fromDate]);

  function getStartOfMonth() {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    return formatDate(startOfMonth);
  }

  function getCurrentDate() {
    const currentDate = new Date();
    // console.log("Current Date:", currentDate);
    return formatDate(currentDate);
  }
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleAnchorClose = () => {
    setAchorOpen(false);

    setAnchorEll(null);
  };
  const handleClick = (event) => {
    setAnchorEll(event.currentTarget);
    setAchorOpen(true);
  };
  const handleOpen = () => {
    if (open === "report") return true;
    else {
      return false;
    }
  };
  const handleClose = () => {
    setOpen("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromDate") {
      setFromDate(value);
    } else if (name === "toDate") {
      setToDate(value);
    }
  };

  const requestHandler = async () => {
    // Create a data object with form values
    const formData = {
      fromDate,
      toDate,
      includeDetailedResults,
      includeAggregatedResults,
      includeBalanceTransactions,
    };

    try {
      // Make a POST request to your API endpoint with Axios
      const response = await axios.post(
        API_URL,
        {
          ...formData,
          mood: "reportGenerate",
          userId: userData?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success === true) {
        // Open a new tab
        const url = response.data.data;
        console.log("URL:", url);
        // navigate("/api/investmentReports/dl/7990", { state: { data } });
        const newTab = window.open(url, "_blank");
        // // Pass the response data to the new tab
        newTab.postMessage(response.data, window.location.origin);
      }
      // Handle the response as needed
      console.log("Form submitted successfully:", response);
    } catch (error) {
      console.error("Error submitting form:", error);
    }

    // window.open("/api/investmentReports/dl/7990", "_blank");
  };

  return (
    <div style={{ flexWrap: "wrap" }}>
      <Modal
        className="report-modal"
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={handleOpen()}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent
          className="report-modal-content"
          // sx={{
          //   width: "600px",
          //   height: "400px",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "space-between",
          // }}
        >
          <h4
            style={{
              fontWeight: "600",
              fontSize: "23px",
              margin: "0 0 10px",
            }}
            id="unstyled-modal-title"
            className="modal-title"
          >
            Report
          </h4>
          <div className="report-model-inner" style={{ display: "flex" }}>
            <div className="date-box">
              <TextField
                sx={{
                  fontSize: "12px !important",
                  "& label.Mui-focused": {
                    fontSize: "12px !important",
                    color: "#37474f",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#37474f",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#37474f",
                    },
                  },
                }}
                label="From"
                fullWidth
                variant="standard"
                type="date"
                name="fromDate"
                value={fromDate}
                onChange={handleChange}
                focused
              />
            </div>
            <div className="date-box">
              <TextField
                sx={{
                  fontSize: "10px !important",
                  "& label.Mui-focused": {
                    fontSize: "12px !important",
                    color: "#37474f",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#37474f",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#37474f",
                    },
                  },
                }}
                label="To"
                fullWidth
                variant="standard"
                type="date"
                name="toDate"
                value={toDate}
                onChange={handleChange}
                focused
              />
            </div>
            <div
              className="report-menu"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <IconButton
                style={{ alignSelf: "flex-end" }}
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>

              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEll}
                open={anchorOpen}
                onClose={handleAnchorClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    maxWidth: ITEM_WIDTH * 4,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option, idx) => (
                  <MenuItem
                    style={{
                      fontSize: "14px",
                      height: "48px",
                      padding: "0 16px",
                      fontWeight: "600",
                      color: "rgba(0,0,0,.87);",
                    }}
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleAnchorClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              ></div>
            </div>
          </div>

          <Box>
            <FormGroup style={{ padding: "16px 0 10px 0" }}>
              <FormControlLabel
                className="report-label"
                style={{
                  fontSize: "12px",
                  color: "#37474f",
                  padding: "1px 0",
                  fontWeight: "800",
                }}
                control={
                  <Checkbox
                    style={{
                      fontSize: "12px",
                      color: "#37474f",
                      padding: "0 5px",
                    }}
                    defaultChecked={includeDetailedResults}
                    onChange={(e) =>
                      setIncludeDetailedResults(e.target.checked)
                    }
                  />
                }
                label="Include detailed trade results"
              />
              <FormControlLabel
                className="report-label"
                style={{ fontSize: "12px", color: "#37474f", padding: "2px 0" }}
                control={
                  <Checkbox
                    style={{
                      fontSize: "12px",
                      color: "#37474f",
                      padding: "0 5px",
                    }}
                    checked={includeAggregatedResults}
                    onChange={(e) =>
                      setIncludeAggregatedResults(e.target.checked)
                    }
                  />
                }
                label="Include aggregated trade results"
              />
              <FormControlLabel
                className="report-label"
                style={{ fontSize: "12px", color: "#37474f", padding: "2px 0" }}
                control={
                  <Checkbox
                    style={{
                      fontSize: "12px",
                      color: "#37474f",
                      padding: "0 5px",
                    }}
                    checked={includeBalanceTransactions}
                    onChange={(e) =>
                      setIncludeBalanceTransactions(e.target.checked)
                    }
                  />
                }
                label="Include balance Transactions"
              />
            </FormGroup>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  textTransform: "capitalize",
                  color: "white",
                  border: "1px solid  #E6E6E6",
                  background: "#37474F",
                  marginRight: "10px",
                }}
                variant="outlined"
                onClick={requestHandler}
              >
                <SimCardDownload style={{ marginRight: "5px" }} />
                Request
              </Button>

              <Button
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  border: "1px solid  #E6E6E6",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;

  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    max-width: 400px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

const TriggerButton = styled("button")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px
        ${theme.palette.mode === "dark" ? blue[300] : blue[200]};
      outline: none;
    }
  `
);
