import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayIcon from "@mui/icons-material/Replay";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../PageLoader.js";
import ResponsiveAppBar from "./AppBar2.js";

const useStyles = styled((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Symbols() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;
  // State for form fields
  const [cryptoName, setCryptoName] = React.useState("");
  const [cryptoVolume, setCryptoVolume] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  // State for crypto data list
  const [cryptoList, setCryptoList] = useState([]);

  // const classes = useStyles();
  // Function to handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Add validation logic if needed
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "symbolAdd",
        symbol: cryptoName,
        volume: cryptoVolume,
      });

      if (response.data.success === true) {
        // Clear form fields
        fetchSymbols();
        setCryptoName("");
        setCryptoVolume("");
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
        });
      } else {
        toast.error(
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
                margin: "0 0 12px 0",
              }}
            >
              {response.data.message}
            </p>
          </div>,

          {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            style: {
              backgroundColor: "#bd362f", // Set your desired background color here
            },
          }
        );
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      });
      console.error("Error signing up:", error);
    }
    // Add the new crypto data to the list
    // setCryptoList([...cryptoList, { name: cryptoName, volume: cryptoVolume }]);
  };

  // Function to handle delete operation
  const handleDelete = async (index) => {
    // Remove the crypto data at the specified index
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "symbolDelete",
        id: index,
      });
      if (response.data.success === true) {
        fetchSymbols();
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
        });
      } else {
        toast.error(
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
                margin: "0 0 12px 0",
              }}
            >
              {response.data.message}
            </p>
          </div>,

          {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            style: {
              backgroundColor: "#bd362f", // Set your desired background color here
            },
          }
        );
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      });
    }
  };

  // useEffect to simulate fetching crypto data from PHP backend on component mount
  useEffect(() => {
    setTimeout(() => {
      fetchSymbols();
    }, 2000);
    // You can replace this with actual API call to PHP backend
    // Example: fetchCryptoDataFromBackend().then(data => setCryptoList(data));
  }, []);

  const fetchSymbols = async () => {
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "listSymbols",
      });
      if (response.data.success === true) {
        setCryptoList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading
    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <ResponsiveAppBar />
      <ToastContainer />

      <div
        className="dashboard history-dashboard"
        style={{ maxWidth: "90%", margin: "0 auto" }}
      >
        <Typography className="history-heading" variant="h5">
          Symbols
        </Typography>
        <div className="table history">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading(true)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container style={{}} spacing={2}>
                  <Grid item lg={4} md={6} sm={12}>
                    <Box
                      className="symbol-add"
                      style={{
                        padding: "0 !important",
                      }}
                    >
                      <Card
                        style={{
                          maxWidth: "100%",
                        }}
                        sx={{ maxWidth: 345 }}
                      >
                        {/* <CardHeader
                          className="symbol-header"
                          title="Symbol Added"
                        /> */}
                        <CardHeader
                          className="symbol-header"
                          title={
                            <Typography variant="h4" fontWeight="bold">
                              Symbol Added
                            </Typography>
                          }
                        />

                        <CardContent>
                          <Box component="form" onSubmit={handleFormSubmit}>
                            {/* <form onSubmit={handleFormSubmit}> */}
                            <TextField
                              style={{
                                marginBottom: "20px",
                              }}
                              id="outlined-search"
                              label="Search field"
                              type="text"
                              fullWidth
                              autoFocus
                              value={cryptoName}
                              onChange={(e) => setCryptoName(e.target.value)}
                              required
                              InputProps={{
                                inputProps: {
                                  style: { color: "#000" }, // Set your desired placeholder color
                                },
                              }}
                            />
                            <TextField
                              style={{
                                marginBottom: "30px",
                              }}
                              id="outlined-number"
                              label="Lot size"
                              type="number"
                              fullWidth
                              autoFocus
                              value={cryptoVolume}
                              onChange={(e) => setCryptoVolume(e.target.value)}
                              required
                            />

                            <Button
                              variant="contained"
                              type="submit"
                              color="primary"
                              style={{
                                marginTop: "15px",
                                background: "rgb(55, 71, 79)",
                                fontSize: "12px",
                              }}
                            >
                              Add Symbol
                            </Button>
                          </Box>

                          {/* <div className="mb-3">
                                <label
                                  htmlFor="cryptoName"
                                  className="form-label"
                                >
                                  Crypto Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cryptoName"
                                  value={cryptoName}
                                  onChange={(e) =>
                                    setCryptoName(e.target.value)
                                  }
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="cryptoVolume"
                                  className="form-label"
                                >
                                  Crypto Volume
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="cryptoVolume"
                                  value={cryptoVolume}
                                  onChange={(e) =>
                                    setCryptoVolume(e.target.value)
                                  }
                                  required
                                />
                              </div>
                              <button type="submit" className="btn btn-primary">
                                Add Crypto
                              </button> */}
                          {/* </form> */}
                        </CardContent>
                        <CardActions disableSpacing></CardActions>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid style={{ width: "100%" }} item lg={8} md={6} sm={12}>
                    <Box className="symbol-add">
                      <TableContainer
                        component={Paper}
                        style={{ overflow: "hidden" }}
                      >
                        <Table sx={{ minWidth: 460 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell align="right">Symbol</TableCell>
                              <TableCell align="right">Volume</TableCell>
                              <TableCell align="right">Actions&nbsp;</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cryptoList.map((row) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className="history-font"
                                  component="th"
                                  scope="row"
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  className="history-font"
                                  align="right"
                                >
                                  {row.symbol}
                                </TableCell>
                                <TableCell
                                  className="history-font"
                                  align="right"
                                >
                                  {row.volume}
                                </TableCell>
                                <TableCell
                                  className="history-font"
                                  align="right"
                                >
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={() => handleDelete(row.id)} // Corrected to use row.id instead of crypto.id
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </div>
      </div>
    </>
  );
}
