import { Modal as BaseModal } from "@mui/base/Modal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Button, LinearProgress, TextField, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { css, styled } from "@mui/system";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResponsiveAppBar from "./AppBar2.js";

export default function Requests() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);

    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ticketData, setTicketData] = useState({
        title: '',
        description: ''
    });
  
      const [hoveredId, setHoveredId] = useState(null);

      const handleMouseEnter = (id) => {
        setHoveredId(id);
      };

      const handleMouseLeave = () => {
        setHoveredId(null);
      };
  
  const handleConfirmDelete = (id) => {
    setConfirmDelete(true);
    setDeleteId(id);
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
    setDeleteId(null);
  };

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal and reset form data
    const closeModal = () => {
        setIsModalOpen(false);
        setTicketData({
            title: '',
            description: ''
        });
    };

    // Function to handle form field changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTicketData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      
      try {

        axios
      .post(API_URL, {
        mood: "support_ticekt_create",
        userId: userData.id,
        title: ticketData.title,
        description: ticketData.description
      })
          .then((response) => {
            console.log(response);
        
            
        // response.data.data != undefined ?   setData(response.data.data) : setData([]);
        // console.log(parseInt(response.data.totalRows));
            setTotalRows(parseInt(response.data.totalRows));
            
            fetchData(1, rowsPerPage);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
          // Send POST request to server
          // const response = await axios.post('http://your-server-endpoint/api/save-ticket.php', {
          //     title: ticketData.title,
          //     description: ticketData.description
          // });

          // console.log('Response from server:', response.data);

          // Close the modal after successful submission
          closeModal();
      } catch (error) {
          console.error('Error submitting ticket:', error);
          // Handle error as needed
      }
    };

  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [data, setData] = React.useState([]);

  // useEffect to fetch data when the component mounts
  React.useEffect(() => {
    // Define the API endpoint
    fetchData(page, rowsPerPage);
  }, []);

  const fetchData = (page, rowsPerPage) => {
    axios
      .post(API_URL, {
        mood: "get_support_ticket",
        userId: userData.id,
        page: page,
        rowsPerPage: rowsPerPage,
      })
      .then((response) => {
        // console.log(response.data.data);
        response.data.data != undefined ? setData(response.data.data) : setData([]);
        // setData(response.data.data)
        // setData(response.data.data);
        // console.log(parseInt(response.data.totalRows));
        setTotalRows(parseInt(response.data.totalRows));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };



  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading

    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleChangePage = (event, newPage) => {
    var pages = newPage + 1;
    setPage(pages);
    fetchData(pages, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPage(1);
    setRowsPerPage(newRowsPerPage);

    fetchData(1, newRowsPerPage);
  };


  const handleDeleteConfirmed = () => {

    axios
      .post(API_URL, {
        mood: "delete_support_ticket",
        userId: userData.id,
        deleteId: deleteId
      })
      .then((response) => {
        // console.log(response.data.data);
        fetchData(page, rowsPerPage);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // Perform deletion using Axios
   
  };



  return (
    <div >
          <>
        <ResponsiveAppBar />
        <ToastContainer />
        <div className="tableTop"></div>

        <div className="table history dashboard-full">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading()}
                style={{ cursor: "pointer" }}
              />
            </div>
                  </div>
                  
          {/* Button to open the modal */}
          <div  style={{
             textAlign: 'right',
              marginBottom: "10px"
            }}>
            <Button variant="contained" color="primary"  onClick={openModal}>
                Create New Ticket
            </Button>
            </div>
          <TableContainer className="transaction-table" component={Paper}>
            {isLoading && <LinearProgress />}
            {!isLoading && (
              <>
                <Box className="transction-table-inner">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Title
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Description
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Create At
                        </TableCell>
                       
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                     {data.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            <Typography variant="subtitle1">Record not found</Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        data.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              className="table-heading-font"
                              style={{ fontSize: "12px" }}
                              component="th"
                              scope="row"
                            >
                              #{row.id}
                            </TableCell>
                            <TableCell className="history-font" align="center">
                              {row.title} 
                            </TableCell>
                            <TableCell className="history-font" align="center">
                              {row.description.length > 60 ? (
                                <span>
                                  {hoveredId === row.id ? (
                                    <span onMouseLeave={handleMouseLeave}>
                                      {row.description}
                                    </span>
                                  ) : (
                                    <span onMouseEnter={() => handleMouseEnter(row.id)}>
                                      {row.description.substring(0, 60)}{row.description.length > 60 ? '...' : ''}
                                    </span>
                                  )}
                                </span>
                              ) : (
                                row.description
                              )}
                            </TableCell>
                            <TableCell className="history-font" align="center">
                              {row.status === 'P' ? 'Pending' : row.status === 'A' ? 'Approved' : 'Rejected'}
                            </TableCell>
                            <TableCell className="table-heading-font" align="center">
                              {row.created_at}
                            </TableCell>
                             <TableCell className="history-font" align="right">
            {row.status === 'P' && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleConfirmDelete(row.id)}
                >
                  Delete
                </Button>
                <Dialog
                  open={confirmDelete && deleteId === row.id}
                  onClose={handleCancelDelete}
                >
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete this item?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmed} color="secondary" variant="contained">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </TableCell>
                          </TableRow>
                        ))
                      )}

                    </TableBody>
                  </Table>
                </Box>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                              
              
                {/* Modal component */}
                <Modal
                
                  slots={{ backdrop: StyledBackdrop }}
                    open={isModalOpen}
                    onClose={closeModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >

                   <ModalContent sx={{ width: 600 }}>
                    <div className="modal-content">
                        {/* Support Ticket Form */}
                        <h2 id="modal-title">Create New Ticket</h2>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Subject "
                                name="title"
                                value={ticketData.title}
                                onChange={handleInputChange}
                                required
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Write your question here"
                                name="description"
                                multiline
                                rows={4}
                                value={ticketData.description}
                                onChange={handleInputChange}
                                required
                        />
                        <div style={{ textAlign: "right", marginTop: "10px"}}>
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                            <Button variant="contained" color="secondary" style={{ marginLeft: "10px" }} onClick={closeModal}>
                                Cancel
                          </Button>
                          </div>
                        </form>
                    </div>
                    </ModalContent>
                </Modal>
              </>
            )}
          </TableContainer>
        </div>
      </>
    </div>
  );
}



const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};


const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    max-width: 480px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 600;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[900]};
      margin-bottom: 4px;
    }
    & #amount-helper-text {
      font-size: 12px;
      font-weight: 600;
    }
  `
);
