import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import axios from "axios";
import numeral from "numeral";
import React from "react";

export default function InvestmentContent() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [responseData, setResponseData] = React.useState({});
  const [currentMonth, setCurrentMonth] = React.useState({});
  const [userDetail, setUserDetails] = React.useState({});

  React.useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const formattedFirstDay = firstDayOfMonth.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    const formattedLastDay = lastDayOfMonth.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    setCurrentMonth({
      firstDay: formattedFirstDay,
      lastDay: formattedLastDay,
    });
    getUserDetails();
    getAccountDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await axios.post(API_URL, {
        mood: "getUserDetails",
        userId: userData.id,
      });
      if (response.data.success === true) {
        setUserDetails(response.data.data);
        // console.log(userDetail);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAccountDetails = async () => {
    await axios
      .post(API_URL, {
        mood: "getAccountDetails",
        userId: userData.id,
      })
      .then((response) => {
        // console.log(response.data);
        setTotalAmount(
          (
            parseInt(response.data.data.totalAmount) +
            parseFloat(response.data.data.netProfit)
          ).toFixed(2)
        );


        setResponseData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  return (
    <>
      <div className="join-parent">
        <Container
          className="main-container"
          component="main"
          style={{ width: "100%", padding: 0, margin: "0 0" }}
        >
          <Box
            sx={{
              boxShadow: 2,
              borderRadius: 1,
              px: 2,
              py: 1,
              marginTop: 1,
              marginBottom: "1.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <p
              className="summery"
              style={{
                display: "flex",
                margin: 0,
                opacity: 0.9,
                width: "100%",
              }}
            >
              Summary
            </p>
            <p
              className="last-update"
              style={{
                display: "flex",
                margin: 0,
                opacity: 0.5,
                width: "100%",
                marginBottom: "0px",
                fontSize: "10px",
                marginTop: "-4px",
              }}
            >
              Last update {formatDateTime(userData?.login_updated_at)}
            </p>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                opacity: "0.7",
                fontWeight: "100",
              }}
            >
              Money Manager
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              PIIPS Capital
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                opacity: "0.6",
              }}
            >
              Created
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              {formatDate(userData?.registration_date)}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0px",
                justifyContent: "space-between",

                fontSize: "14px",
                gap: "10px",
                height: "22px",
                fontWeight: "100",
                opacity: "0.6",
              }}
            >
              Trading interval
              <div
                className="trading-center"
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>
                {" "}
                {currentMonth.firstDay} – {currentMonth.lastDay}{" "}
              </span>
            </div>
            {/* <div
              style={{
                margin: 0,
                marginTop: "17px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Min. Initial Investment</span>

              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round,
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>$0.00</span>
            </div> */}

            <div
              style={{
                margin: 0,
                marginTop: "17px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                opacity: "0.6",
                fontWeight: "100",
                justifyContent: "space-between",
              }}
            >
              <span>Current trading interval profit</span>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span
                style={{
                  // color: "#2FB54E",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {responseData?.currentTradingInterval >= 0
                  ? "$" +
                    numeral(responseData?.currentTradingInterval).format(
                      "0,0.00"
                    )
                  : "-$" +
                    Math.abs(
                      numeral(responseData?.currentTradingInterval).format(
                        "0,0.00"
                      )
                    )}
              </span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Open Trades Profit</span>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>$0.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0",
                opacity: "0.9",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",

                fontWeight: "100",
              }}
            >
              <span>Closed Trades Profit</span>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span
                style={{
                  color: responseData?.netProfit < 0 ? "#dc3545" : "#28a745",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {responseData?.netProfit >= 0
                  ? "$" + numeral(responseData?.netProfit).format("0,0.00")
                  : "-$" +
                    Math.abs(numeral(responseData?.netProfit).format("0,0.00"))}
              </span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0",
                opacity: "0.9",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                fontWeight: "100",
              }}
            >
              <span>Trade Results </span>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span
                style={{
                  color: responseData?.netProfit < 0 ? "#dc3545" : "#28a745",
                  fontSize: "150%",
                  fontWeight: "bold",
                }}
              >
                {responseData?.netProfit >= 0
                  ? "$" + numeral(responseData?.netProfit).format("0,0.00")
                  : "-$" +
                    Math.abs(numeral(responseData?.netProfit).format("0,0.00"))}
              </span>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <Accordion
                style={{
                  width: "100%",
                  border: "0",
                  boxShadow: "none",
                  justifyContent: "space-between",
                }}
              >
                <AccordionSummary
                  className="accordion-fees"
                  style={{
                    width: "100%",
                    padding: "0 0 0 0",
                    margin: "0 0",
                    minHeight: "20px",
                    justifyContent: "space-between",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "0 0 !important",
                    }}
                  >
                    <Typography
                      className="fees-text"
                      style={{
                        fontWeight: "600",
                        paddingRight: "35px",
                        fontSize: "15px",
                      }}
                    >
                      Fees
                    </Typography>
                    <div
                      style={{
                        flex: 1,
                        marginTop: "1px", // Adjust as needed
                        marginBottom: "1px", // Adjust as needed
                        borderBottom: "1px dotted #ddd",
                        backgroundClip: "padding-box", // Ensures the dots are round
                        fontWeight: "600",
                      }}
                    />
                    <Typography>-${responseData?.transactionFee}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    padding: "4px 0px 10px 12px",
                  }}
                >
                  <Typography>
                    <div>
                      <span style={{ opacity: "0.6", fontSize: "14px" }}>
                        Performance Fees
                      </span>
                      <span style={{ opacity: "0.6", float: "right" }}>
                        -${responseData?.transactionFee}
                      </span>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

            <div
              style={{
                width: "100%",
              }}
            >
              <Accordion
                className="net-deposite"
                style={{
                  width: "100%",
                  display: "inline-block",
                  border: "0",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  style={{
                    padding: "0",
                    margin: "0 0 !important",
                    minHeight: "20px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-headers"
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "0 0 !important",
                    }}
                  >
                    <Typography
                      className="fees-text"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        paddingRight: "35px",
                      }}
                    >
                      Net Deposit
                    </Typography>
                    <div
                      style={{
                        flex: 1,
                        marginTop: "1px", // Adjust as needed
                        marginBottom: "1px", // Adjust as needed
                        borderBottom: "1px dotted #ddd",
                        backgroundClip: "padding-box", // Ensures the dots are round
                        opacity: "0.6",
                        fontWeight: "100",
                      }}
                    />
                    <Typography
                      style={{ fontWeight: "600", textAlign: "right" }}
                    >
                      $
                      {numeral(
                        userDetail.depositAmount - userDetail.withdrawalAmount
                      ).format("0,0.00")}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: "0",
                  }}
                >
                  <Typography>
                    <div
                      style={{
                        justifyContent: "space-between",
                        opacity: "0.6",
                        fontWeight: "100",
                      }}
                    >
                      <span style={{ opacity: "0.6", fontSize: "14px" }}>
                        Deposit
                      </span>
                      <span style={{ float: "right" }}>
                        {" "}
                        <span>
                          ${numeral(userDetail.depositAmount).format("0,0.00")}
                        </span>
                      </span>
                    </div>
                  </Typography>
                  <Typography>
                    <div>
                      <span style={{ opacity: "0.6", fontSize: "14px" }}>
                        Withdraw
                      </span>
                      <span style={{ float: "right" }}>
                        ${numeral(userDetail.withdrawalAmount).format("0,0.00")}
                      </span>
                    </div>
                  </Typography>{" "}
                  {/* Corrected typo in "Deposite" */}
                </AccordionDetails>
              </Accordion>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0",
                opacity: "0.9",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                fontWeight: "100",
              }}
            >
              <span>Net Profit</span>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span
                style={{
                  color:
                    responseData?.netProfit - responseData?.transactionFee < 0
                      ? "#dc3545"
                      : "#28a745",
                  fontSize: "150%",
                  fontWeight: "600",
                }}
              >
                {responseData?.netProfit - responseData?.transactionFee >= 0
                  ? "$" +
                    numeral(
                      responseData?.netProfit - responseData?.transactionFee
                    ).format("0,0.00")
                  : "-$" +
                    numeral(
                      Math.abs(
                        responseData?.netProfit - responseData?.transactionFee
                      )
                    ).format("0,0.00")}
              </span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0",
                marginTop: "0",

                opacity: "0.9",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                fontWeight: "100",
              }}
            >
              <span>Balance</span>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>${numeral(totalAmount).format("0,0.00")}</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0",
                opacity: "0.9",
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",

                fontWeight: "100",
              }}
            >
              <span>Funds</span>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span style={{ fontSize: "150%", fontWeight: "700" }}>
                ${totalAmount}
              </span>
            </div>
          </Box>
        </Container>
      </div>
    </>
  );
}
