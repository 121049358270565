import Button from "@mui/material/Button";
import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResponsiveAppBar from "./AppBar2.js";

export default function History() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const amount = queryParams.get("amount");
  // State to manage the payment confirmation status
  const [paymentConfirmed, setPaymentConfirmed] = React.useState(false);

  // Function to handle the payment confirmation
  const handleConfirmPayment = async () => {
    if (!isNaN(amount) && Number(amount) > 0) {
      setPaymentConfirmed(true);
      // Add logic to handle payment confirmation, e.g., make an API call
      try {
        const response = await axios.post(`${API_URL}`, {
          email: userData?.email,
          server: userData?.server,
          username: userData?.username,
          userId: userData?.id,
          amount: amount,
          mood: "deposit",
        });

        //   setMessage(response.data.message);
        if (response.data.success === true) {
          toast.success(response.data.message, {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            navigate(`/app/history?query=request`);
          }, 2000);
        } else {
          toast.error(response.data.message, {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        // console.error("Error signing up:", error);
        toast.error(error, {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      // Display an error message if the amount is not a valid number
      toast.error(
        <div>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "600",
              margin: "0 0 12px 0",
            }}
          >
            Please enter a valid number for the amount.
          </p>
        </div>,

        {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          style: {
            backgroundColor: "#bd362f", // Set your desired background color here
          },
        }
      );
    }
    // Update the state to reflect the payment confirmation
  };
  return (
    <>
      <ResponsiveAppBar />
      <ToastContainer />

      <div className="transaction-status-sec">
        <div className="container">
          <div className="transaction-status-inner">
            <div className="transaction-status-box">
              <table>
                <thead>
                  <tr>
                    <th colSpan="2">Payment Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Status:</td>
                    <td>Waiting for your funds...</td>
                  </tr>
                  <tr>
                    <td>Total Amount To Send:</td>
                    <td>{amount} USDT.TRC20 (total confirms needed: 10)</td>
                  </tr>
                  <tr>
                    <td>Received So Far:</td>
                    <td>0.00000000 USDT.TRC20 (unconfirmed)</td>
                  </tr>
                  <tr>
                    <td>Balance Remaining:</td>
                    <td>{amount} USDT.TRC20</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="scan-img">
                      <img src="/assets/qr.jpeg" alt="Qr code" />
                    </td>
                  </tr>
                  <tr>
                    <td>Send To Address:</td>
                    <td>TGgYLDR8CQvcK5EXZ7YcGzPSUtqF7XwT2X</td>
                  </tr>
                  <tr>
                    <td>Seller: </td>
                    <td> Zenfinex Global Limited</td>
                  </tr>
                  {/* Confirm Payment button */}
                  <tr>
                    <td colSpan="2" style={{ textAlign: "center" }}>
                      {paymentConfirmed ? (
                        <p>Payment are inprogress</p>
                      ) : (
                        // <button
                        //   style={{ width: "150px", height: "50px" }}
                        //   onClick={handleConfirmPayment}
                        // >
                        //   Confirm
                        //   </button>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            "background-color": "#786564",
                            height: "52px",
                          }}
                          sx={{ mt: 3 }}
                          onClick={handleConfirmPayment}
                        >
                          Confirm
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
