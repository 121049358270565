import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Divider, Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid"; // Grid version 1
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { default as ResponsiveAppBar2 } from "./AppBar2.js";
import HistoryDetails from "./HistoryDetails.js";
import InvestmentContent from "./InvestmentContent.js";
import "./InvestmentDetails.css";
import { default as DepositModal } from "./Modal/DepositModal.js";
import EditModal from "./Modal/EditModal.js";
import ReportModal from "./Modal/ReportModal.js";
import WithdrawModal from "./Modal/WithdrawModal.js";
import OfferDetails from "./OfferDetails.js";
import PositionDetails from "./PositionDetails.js";
const ITEM_HEIGHT = 48;
const ITEM_WIDTH = 40;
const options = ["Edit", "Close Account"];

export default function InvestmentDetails2() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //

  const [toggle, setToggle] = React.useState(0);

  return (
    <>
      <ResponsiveAppBar2 />
      <div
        className="dashboard"
        style={{
          marginBottom: "30px",
          maxWidth: "96%",
          // padding: "15px",
          margin: "auto",
        }}
      >
        <div
          className="investments-first-div"
          style={{ alignItems: "center", margin: "30px 0 0 0" }}
        >
          <Typography
            style={{ paddingLeft: "0", fontSize: "14px", marginBottom: "10px" }}
            variant="h6"
          >
            Investment #066
          </Typography>

          <Typography
            style={{
              paddingLeft: "0",
              marginBottom: "20px",
              fontSize: "24px",
              fontWeight: "bold",
            }}
            variant="h3"
          >
            PIIPS Capital
          </Typography>
          <Button
            onClick={() => setOpenModal("deposit")}
            className="investment-details-button"
            style={{
              marginBottom: "8px",
              textTransform: "capitalize",
              marginRight: "8px",
              color: "black",
              fontWeight: "bold",
              border: "1px solid  #E6E6E6",
            }}
            variant="outlined"
          >
            {/* <MonetizationOnIcon
              style={{ marginRight: "7px", height: "15px" }}
            /> */}
            <img
              src="/assets/icon/deposit.png"
              alt="logo"
              style={{ width: "15px", height: "18px", marginRight: "7px" }}
            />
            Deposit
          </Button>
          <Button
            className="investment-details-button"
            onClick={() => setOpenModal("withdrawal")}
            style={{
              marginBottom: "8px",
              textTransform: "capitalize",
              marginRight: "8px",

              color: "black",
              border: "1px solid  #E6E6E6",
              fontWeight: "600",
            }}
            variant="outlined"
          >
            {/* <RemoveCircleOutlineIcon style={{ marginRight: "7px" }} /> */}
            <img
              src="/assets/icon/withdrawl.png"
              alt="logo"
              style={{ width: "17px", height: "15px", marginRight: "7px" }}
            />
            Withdraw
          </Button>
          <Button
            className="investment-details-button"
            onClick={() => setOpenModal("report")}
            style={{
              marginBottom: "8px",
              textTransform: "capitalize",
              marginRight: "8px",
              fontWeight: "600",
              color: "black",
              border: "1px solid  #E6E6E6",
            }}
            variant="outlined"
          >
            {/* <SummarizeIcon style={{ marginRight: "7px" }} /> */}
            <img
              src="/assets/icon/report.png"
              alt="logo"
              style={{ width: "16px", height: "19px", marginRight: "7px" }}
            />
            Report
          </Button>

          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            style={{
              marginBottom: "8px",
              transform: "rotate(90deg)",
              color: "black",
              fontWeight: "600",
            }}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                maxWidth: ITEM_WIDTH * 4,
                width: "20ch",
              },
            }}
          >
            {options.map((option, idx) => (
              <MenuItem
                style={{ fontSize: "14px" }}
                key={option}
                selected={option === "Pyxis"}
                // onClick={handleClose}
              >
                {idx === 1 ? (
                  <PersonOffIcon style={{ marginRight: "8px", color: "red" }} />
                ) : (
                  <EditIcon
                    onClick={() => setOpenModal("editModel")}
                    style={{ marginRight: "8px" }}
                  />
                )}
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <Grid
          item
          xs={1}
          style={{ height: "0" }}
          color={"black"}
          display={"flex"}
          justifyContent={"flex-start"}
        >
          <Button></Button>
        </Grid>

        <div style={{ margin: "0", width: "100%" }}>
          <DepositModal open={openModal} setOpen={setOpenModal} />
          <WithdrawModal open={openModal} setOpen={setOpenModal} />
          <ReportModal open={openModal} setOpen={setOpenModal} />
          <EditModal open={openModal} setOpen={setOpenModal} />
        </div>
      </div>

      <div
        style={{
          maxWidth: "100%",
          display: "flex",
          textAlign: "center",
          border: "none",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <Tabs
          // value={value}
          // onChange={handleChange}
          variant="scrollable"
          aria-label="scrollable force tabs example"
          // scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            // label="Investment"
            onClick={() => setToggle(0)}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <InfoIcon style={{ marginRight: "7px" }} /> */}
                <img
                  src="/assets/icon/invest.png"
                  alt="logo"
                  style={{ width: "24px", height: "27px", marginRight: "7px" }}
                />
                <span>Investment</span>
              </div>
            }
            style={{
              display: "flex",
              border: "none",
              color: "black",
              textTransform: "capitalize",
              paddingRight: "30px",
              paddingLeft: "30px",
              borderBottom: toggle === 0 ? "2px solid black" : "0px", // Adjusted the ternary expression
            }}
          ></Tab>
          <Tab
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <RequestPageIcon style={{ marginRight: "7px" }} /> */}
                <img
                  src="/assets/icon/offer.png"
                  alt="logo"
                  style={{ width: "21px", height: "28px", marginRight: "7px" }}
                />
                <span>Offers</span>
              </div>
            }
            onClick={() => setToggle(1)}
            style={{
              border: "none",
              color: "black",
              textTransform: "capitalize",
              paddingRight: "30px",
              paddingLeft: "30px",

              borderBottom: toggle === 1 ? "2px solid black" : "0px", // Adjusted the ternary expression
            }}
          />
          <Tab
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <DescriptionIcon style={{ marginRight: "7px" }} /> */}
                <img
                  src="/assets/icon/positions.png"
                  alt="logo"
                  style={{ width: "20px", height: "24px", marginRight: "7px" }}
                />
                <span>Position</span>
              </div>
            }
            onClick={() => setToggle(2)}
            style={{
              border: "none",
              color: "black",
              textTransform: "capitalize",
              paddingRight: "30px",
              paddingLeft: "30px",

              borderBottom: toggle === 2 ? "2px solid black" : "0px", // Adjusted the ternary expression
            }}
          />
          <Tab
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <RestoreIcon style={{ marginRight: "7px" }} /> */}
                <img
                  src="/assets/icon/history.png"
                  alt="logo"
                  style={{ width: "23px", height: "25px", marginRight: "7px" }}
                />
                <span>History</span>
              </div>
            }
            onClick={() => setToggle(3)}
            style={{
              border: "none",
              color: "black",
              textTransform: "capitalize",
              maxWidth: "200px",
              paddingRight: "30px",
              paddingLeft: "30px",
              borderBottom: toggle === 3 ? "2px solid black" : "0px", // Adjusted the ternary expression
            }}
          />
        </Tabs>
      </div>
      <Divider style={{ margin: "0px auto", width: "96%" }} />
      <div className="investment-table" style={{ marginTop: "30px" }}>
        {toggle === 0 && <InvestmentContent />}
        {toggle === 1 && <OfferDetails />}
        {toggle === 2 && <PositionDetails />}
        {toggle === 3 && <HistoryDetails />}
      </div>
      <Divider
        className="devider"
        style={{ height: "30px", marginBottom: "80px" }}
      />
    </>
  );
}
