import React from "react";
import ResponsiveAppBar from './AppBar';


export default function Home() {
  return (
    <div>
      <ResponsiveAppBar />
    </div>
  );
}
