import * as React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ResponsiveAppBar from "./AppBar2.js";
import Requests from "./Requests.js";
import Transactions from "./Transactions.js";

export default function History() {
  const [activeButton, setActiveButton] = React.useState("transactions");

  return (
    <>
      <ResponsiveAppBar />

      <div
        className="dashboard history-dashboard"
        style={{
          maxWidth: "90%",
          margin: "0 auto",
        }}
      >
        <Typography className="history-heading" variant="h5">
          History
        </Typography>

        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            className="history-font"
            style={{
              padding: ".5rem",
              color: "black",
              backgroundColor:
                activeButton === "transactions" ? "#D7D7D7" : "white",
              borderColor: "#D7D7D7",
              textTransform: "capitalize",
              fontSize: "13px",
            }}
            onClick={() => setActiveButton("transactions")}
          >
            Transactions
          </Button>
          <Button
            className="history-font"
            style={{
              color: activeButton === "requests" ? "black" : "black",
              backgroundColor:
                activeButton === "requests" ? "#D7D7D7" : "transparent",
              borderColor: "#D7D7D7",
              textTransform: "capitalize",
              fontSize: "13px",
            }}
            onClick={() => setActiveButton("requests")}
          >
            Requests
          </Button>
        </ButtonGroup>

        {activeButton === "transactions" && <Transactions />}
        {activeButton === "requests" && <Requests />}
      </div>
    </>
  );
}
