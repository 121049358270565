import { ArrowBack } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DoneIcon from "@mui/icons-material/Done";
import LockIcon from "@mui/icons-material/Lock";
import MoneyIcon from "@mui/icons-material/Money";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Language from "./Languages";
import "./oldJoin.css";

export default function OldJoin() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    // navigate("/home");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    // console.log(server);
    // console.log({
    //   email: data.get("email"),
    //   password: data.get("password"),
    //   initial_investment: data.get("initial_investment"),
    // });

    try {
      const response = await axios.post(`${API_URL}`, {
        password: data.get("password"),
        email: data.get("email"),
        full_name: data.get("full_name"),
        account: data.get("mt-account"),
        initial_investment: data.get("initial_investment"),
        server: server,
        mood: "signup",
      });
      setLoading(false);
      //   setMessage(response.data.message);
      if (response.data.success === true) {
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        navigate("/app/auth/investor");
      } else {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      // console.error("Error signing up:", error);
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [server, setServer] = React.useState("zenfinexGlobal-live");
  const [mtAccount, setMtAccount] = React.useState("");
  const [error, setError] = useState(false);
  const [formData, setFormData] = React.useState({
    performanceFee: "",
    managementFee: "",
    withdrawalFees: "",
    depositFees: "",
  });

  const handleChange = (event) => {
    setServer(event.target.value);
  };

  const handleChange2 = (event) => {
    const inputValue = event.target.value;
    setMtAccount(inputValue);
    setError(inputValue.trim() === "");
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange3 = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { gilad, jason, antoine } = state;
  const error1 = [gilad, jason, antoine].filter((v) => v).length !== 2;

  React.useEffect(() => {
    // Optionally, you can fetch initial data when the component mounts
    const fetchData = async () => {
      try {
        const fetchDataResponse = await axios.post(API_URL, {
          mood: "getSettingFees",
        });
        const fetchedData = fetchDataResponse.data.data;
        // console.log(fetchedData.performance_fee);
        // Update the form data with fetched data
        setFormData({
          ...formData,
          performanceFee: fetchedData.performance_fee,
          managementFee: fetchedData.management_fee,
          withdrawalFees: fetchedData.withdrawal_fees,
          depositFees: fetchedData.deposit_fees,
        });
        // console.log(formData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Run this effect only once when the component mounts

  return (
    <>
      <div style={{ height: "100%", width: "100%" }} className="">
        <ToastContainer />
        <div
          className="investorHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0.7rem 2rem",
          }}
        >
          <Button
            style={{
              textTransform: "capitalize",
              color: "black",
              border: "1px solid  #E6E6E6",
            }}
            variant="outlined"
            onClick={() => navigate("/registerinvestor")}
          >
            <ArrowBack fontSize="small" style={{ marginRight: "5px" }} />
            Back to Sign in
          </Button>
          <Language />
        </div>
        <Container component="main" style={{ maxWidth: "600px", padding: 0 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/taurex_logo.png"
              alt="logo"
              style={{ width: "200px", height: "auto", marginTop: "2rem" }}
            />
          </div>
          <p
            className="login-heading"
            style={{
              marginTop: "3rem",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                font: "400 22px/32px Nunito,sans-serif",
                color: "rgb(0 0 0 / 87%)",
                fontWeight: "600",
                fontsize: "22px",
                display: "block",
              }}
            >
              PAMM - Taurex
              <span
                style={{
                  opacity: ".5",
                  paddingLeft: "10px",
                  color: "#6c757d",
                  fontWeight: "700",
                }}
              >
                Investor Registration
              </span>
            </span>
          </p>

          <Box
            sx={{
              boxShadow: 2,
              borderRadius: 1,
              px: 2,
              py: 1,
              marginTop: 1,
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              margin: "20px auto",
              maxWidth: "85%",
            }}
          >
            <p
              style={{
                display: "flex",
                margin: 0,
                width: "100%",
                fontSize: "24px",
                fontWeight: "500",
                color: "rgba(0,0,0,0.87)",
              }}
            >
              PIIPS CAPITAL PAMM Trading A/c
            </p>
            <p
              style={{
                display: "flex",
                margin: 0,
                width: "100%",
                marginBottom: "5px",
                fontSize: "13px",
                marginTop: "-4px",
                color: "#6c757d",
              }}
            >
              Money Manager #1066
            </p>
            <p
              style={{
                display: "flex",
                margin: 0,
                opacity: 0.9,
                width: "100%",
                marginTop: "1rem",
                fontSize: "14px",
                opacity: "0.6",
                fontWeight: "100",
              }}
            >
              Most Secure Forex Fund Management company
            </p>
          </Box>

          <Box
            sx={{
              boxShadow: 2,
              borderRadius: 1,
              px: 2,
              py: 1,
              marginTop: 1,
              marginBottom: "1.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              margin: "20px auto",
              maxWidth: "85%",
            }}
          >
            <p
              style={{
                display: "flex",
                margin: 0,
                // opacity: 0.9,
                width: "100%",
              }}
            >
              Offer
            </p>
            <p
              style={{
                display: "flex",
                margin: 0,
                // opacity: 0.5,
                width: "100%",
                marginBottom: "0px",
                fontSize: "14px",
                marginTop: "-4px",
                // color: "#6c757d",
              }}
            >
              PIIPS CAPITAL
            </p>
            <p
              style={{
                display: "flex",
                margin: 0,
                // opacity: 0.9,
                width: "100%",
                marginTop: "0.7rem",
                marginBottom: "12px",
                fontSize: "14px",
                color: "#6c757d",
                fontWeight: "100",
              }}
            >
              Most Safest Forex fund Management company
            </p>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                // color: "#6c757d",
                fontWeight: "100",
              }}
            >
              <span
                className=""
                style={{
                  color: "#000",
                  fontWeight: "100",
                  fontSize: "14px",
                }}
              >
                Trading Interval
              </span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseClick={() => setOpen(!open)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  // opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              1 Month
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                // opacity: "0.6",
              }}
            >
              Min. Deposit
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                              // opacity: "0.6",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseEnter={() => setOpen(true)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  // opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              $100.00
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                fontWeight: "100",
                // opacity: "0.6",
              }}
            >
              Min. Withdrawal
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseEnter={() => setOpen(true)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  // opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>$100.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Min. Initial Investment</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseEnter={() => setOpen(true)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round,
                  // opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>$100.00</span>
            </div>

            <Divider
              color="black"
              sx={{
                marginTop: "1.5rem",
                marginBottom: "0.5rem",
                width: "106%",
                borderColor: "#cccccc", // Optional: Set the color of the dashed line
                // borderWidth: '1px', // Optional: Set the width of the dashed line
              }}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Performance Fees</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseEnter={() => setOpen(true)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                        // opacity: "0.6",
                        fontWeight: "100",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Equity</span>
              <span>$0.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Performance fee (%)</span>
              <span>{formData.performanceFee}%</span>
            </div>

            <Divider
              color="black"
              sx={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                width: "106%",
                borderColor: "#cccccc", // Optional: Set the color of the dashed line
                // borderWidth: '1px', // Optional: Set the width of the dashed line
                // opacity: "0.6",
                fontWeight: "100",
              }}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Management Fees</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseEnter={() => setOpen(true)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Equity</span>
              <span>$0.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Management fee (%)</span>
              <span>{formData.managementFee}%</span>
            </div>

            <Divider
              color="black"
              sx={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                width: "106%",
                borderColor: "#cccccc", // Optional: Set the color of the dashed line
                // borderWidth: '1px', // Optional: Set the width of the dashed line
              }}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Withdrawal Fees</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseEnter={() => setOpen(true)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Equity</span>
              <span>$0.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Fee (% of amount)</span>
              <span>{formData.withdrawalFees}%</span>
            </div>

            <Divider
              color="black"
              sx={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                width: "106%",
                borderColor: "#cccccc", // Optional: Set the color of the dashed line
                // borderWidth: '1px', // Optional: Set the width of the dashed line
                // opacity: "0.6",
                fontWeight: "100",
              }}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Deposit Fees</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      // onClick={handleTooltipOpen}
                      // onMouseEnter={() => setOpen(true)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Deposit amount (from)</span>
              <span>${formData.depositFees}</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                // opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
                // opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Fee (% of amount)</span>
              <span>0%</span>
            </div>
          </Box>

          <Box
            sx={{
              boxShadow: 2,
              borderRadius: 1,
              px: 2,
              py: 2,
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              // opacity: "0.6",
              fontWeight: "100",
              margin: "20px auto",
              maxWidth: "85%",
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Box sx={{ minWidth: 120 }}>
                <p
                  style={{
                    display: "flex",
                    margin: 0,
                    fontWeight: "100",
                  }}
                >
                  Investor
                </p>
                <FormControl
                  margin="normal"
                  fullWidth
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "15.75px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Server</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={server}
                    label="Server"
                    onChange={handleChange}
                  >
                    <MenuItem value={"zenfinexGlobal-live"}>
                      zenfinexGlobal-live
                    </MenuItem>
                    <MenuItem value={"zenfinexGlobal-live2"}>
                      zenfinexGlobal-live2
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                id="mt-account"
                label="MT Account"
                name="mt-account"
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle style={{ color: "black" }} />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                id="fullname"
                label="Full name"
                name="full_name"
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle style={{ color: "black" }} />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                id="email"
                label="Email"
                name="email"
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle style={{ color: "black" }} />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                name="password"
                label="Password"
                type="password"
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockIcon style={{ color: "black" }} />
                    </InputAdornment>
                  ),
                }}
                autoComplete="current-password"
              />
              <TextField
                defaultValue={100}
                margin="normal"
                required
                error={error}
                fullWidth
                onChange={handleChange2}
                name="initial_investment"
                label="Initial Investment"
                type="number"
                id="initial_investment"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <MoneyIcon style={{ color: "black" }} />
                    </InputAdornment>
                  ),
                }}
                helperText="USD min $100.00"
              />

              <Divider
                // color="black"
                sx={{
                  marginTop: "1.5rem",
                  marginBottom: "1.5rem",
                  // width: "106%",
                  borderColor: "#cccccc", // Optional: Set the color of the dashed line
                  // borderWidth: '1px', // Optional: Set the width of the dashed line
                }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  flexDirection: "column",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={gilad}
                      onChange={handleChange3}
                      name="gilad"
                    />
                  }
                  label={
                    <React.Fragment>
                      <div>
                        <p
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                            margin: 0,
                          }}
                        >
                          I agree to terms of PAMM service
                        </p>
                      </div>
                    </React.Fragment>
                  }
                />
                {/* <a
                  href="https://pamm4.vantagemarkets.com/app/assets/terms/PAMMServiceAgreement.pdf"
                  style={{
                    fontSize: "15px",
                    margin: 0,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: "30px",
                    marginTop: "-10px",
                    marginBottom: "-10px",
                    textDecoration: "none",
                  }}
                >
                  check the agreement
                </a> */}
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleLogin}
                disabled={loading}
                style={{
                  backgroundColor: "#37474f",
                  height: "52px",
                  textTransform: "none",
                }}
                sx={{ mt: 3 }}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  <>
                    <DoneIcon style={{ color: "white", fontWeight: "400" }} />
                    <span style={{ paddingLeft: "10px" }}>Invest</span>
                  </>
                )}

                {/* <DoneIcon style={{ color: "white", fontWeight: "400" }} />
                <span style={{ paddingLeft: "10px" }}>Invest</span> */}
              </Button>
            </Box>
          </Box>
        </Container>
        <p style={{ paddingBottom: "40px" }}></p>
      </div>
    </>
  );
}
