import {
  AccountCircleRounded,
  AutoFixHigh,
  Brightness4Rounded,
  ChevronRight,
  DarkMode,
  LightMode,
} from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HistoryIcon from "@mui/icons-material/History";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuIcon from "@mui/icons-material/Menu";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Language from "./Languages";

const pages = ["Investments", "History", "Support"];
// const settings = ["Investor #8656111", "Theme", "Logout"];

// const CustomTypography = styled(Typography)`
//   /* Use the styles defined in the CSS file */
//   composes: customTypography;
// `;

// const CustomLink = styled("div")`
//   /* Use the styles defined in the CSS file */
//   composes: customLink;
// `;

function ResponsiveAppBar2() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  // const API_URL = "http://taurex-php.rb:8080/api.php";
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [themeAnchor, setThemeAnchor] = useState(null);
  // const [open, setOpen] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState(null);
  // const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (e, index) => {
    console.log(index);
    if (index === 0) navigate("/app/investments");
    else {
      navigate("/app/history");
    }
    setAnchorElNav(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenThemeMenu = (event) => {
    setThemeAnchor(event.currentTarget);
  };

  const handleCloseThemeMenu = () => {
    setThemeAnchor(null);
  };

  const Logout = async () => {
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "logout",
      });

      if (response.data.success === true) {
        // alert("Login Successful");
        sessionStorage.clear();
        setTimeout(() => {
          navigate("/app/auth");
        }, 1000);
      } else {
        toast.error(
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
                margin: "0 0 12px 0",
              }}
            >
              {response.data.message}
            </p>
            <span
              style={{
                fontSize: "14px",
                display: "block",
                lineHeight: "0",
                marginBottom: "5px",
              }}
            >
              Invalid login/password
            </span>
          </div>,

          {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            style: {
              backgroundColor: "#bd362f", // Set your desired background color here
            },
          }
        );
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      });
      console.error("Error signing up:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div style={{ backgroundColor: "black", marginBottom: "3rem" }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "black", maxWidth: "90%", margin: "auto" }}
        >
          <Container
            className="appbar-container"
            style={{ padding: "0 20px", maxWidth: "100%" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/assets/taurex_logo.png"
                className="taurex-logo"
                alt="logo"
              />

              <Box
                style={{ justifyContent: "end" }}
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  style={{ fontSize: "12px" }}
                  align="center"
                >
                  {pages.map((page, index) => (
                    <MenuItem
                      className="appbar-links"
                      style={{ fontSize: "13px" }}
                      align="center"
                      key={page}
                      onClick={(e) => handleCloseNavMenu(e, index)}
                    >
                      {page}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                }}
              >
                {pages.map((page, index) => (
                  <Button
                    key={page}
                    component={Link}
                    to={`/app/${page.toLowerCase()}`}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {index === 0 && <TrendingUpIcon sx={{ mr: 1 }} />}
                    {index === 1 && <HistoryIcon sx={{ mr: 1 }} />}
                    {index === 2 && <HistoryIcon sx={{ mr: 1 }} />}
                    {page}
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                {/* <Tooltip title="Open settings"> */}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <IconButton
                    className="appbar-buttons-container"
                    style={{
                      display: "block",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <Language />
                  </IconButton>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircleOutlinedIcon
                      style={{
                        color: "#999999",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    />
                    <p
                      className="investor-name"
                      style={{
                        color: "#999999",
                        fontSize: "15px",
                        marginLeft: "5px",
                        alignItems: "center",
                        display: "flex",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        ...(isMobile && {
                          display: "none",
                        }),
                      }}
                    >
                      {userData.full_name}
                    </p>
                  </IconButton>
                </div>
                {/* </Tooltip> */}
                <Menu
                  sx={{ mt: "45px", mr: "200px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => navigate("/app/my-account")}>
                    <AccountCircleRounded style={{ marginRight: "11px" }} />
                    <Typography style={{ fontSize: "13px" }} textAlign="center">
                      Investor #{userData.id}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => {}}
                    onMouseOver={handleOpenThemeMenu}
                    onMouseLeave={handleCloseThemeMenu}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Brightness4Rounded style={{ marginRight: "11px" }} />
                      <Typography
                        style={{ fontSize: "13px" }}
                        textAlign="center"
                      >
                        Theme
                      </Typography>
                    </div>
                    <ChevronRight />

                    {/* start of submenu  */}
                    <Menu
                      id="menu-appbar"
                      anchorEl={themeAnchor}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(themeAnchor)}
                      onClose={handleCloseThemeMenu}
                    >
                      <MenuItem>
                        <LightMode style={{ marginRight: "11px" }} />
                        <Typography
                          style={{ fontSize: "13px" }}
                          textAlign="center"
                        >
                          Light
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <DarkMode style={{ marginRight: "11px" }} />
                        <Typography
                          style={{ fontSize: "13px" }}
                          textAlign="center"
                        >
                          Dark
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <AutoFixHigh style={{ marginRight: "11px" }} />
                        <Typography
                          style={{ fontSize: "13px" }}
                          textAlign="center"
                        >
                          Auto
                        </Typography>
                      </MenuItem>
                    </Menu>

                    {/* end of submenu */}
                  </MenuItem>
                  <MenuItem onClick={() => Logout()}>
                    <LogoutRoundedIcon style={{ marginRight: "11px" }} />
                    <Typography style={{ fontSize: "13px" }} textAlign="center">
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          </Container>
        </AppBar>
      </div>
    </>
  );
}

export default ResponsiveAppBar2;
