import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Divider,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid"; // Grid version 1
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import axios from "axios";
import numeral from "numeral";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { default as ResponsiveAppBar2 } from "./AppBar2.js";
import "./investments.css";

export default function BasicTable() {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  // const [data, setData] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [responseData, setResponseData] = React.useState([]);
  const [currentMonth, setCurrentMonth] = React.useState({});

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const formattedFirstDay = firstDayOfMonth.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    const formattedLastDay = lastDayOfMonth.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    setCurrentMonth({
      firstDay: formattedFirstDay,
      lastDay: formattedLastDay,
    });
  }, []);

  React.useEffect(() => {
    // Assuming API_URL is defined somewhere in your code
    const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;

    axios
      .post(API_URL, {
        mood: "getAccountDetails",
        userId: userData.id,
      })
      .then((response) => {
        console.log(response.data);

        setTotalAmount(
          parseInt(response.data.data.totalAmount) +
            parseFloat(response.data.data.netProfit)
        );
        setResponseData(response.data.data);
        console.log(responseData?.netProfit);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleStartLoading = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  function createData(
    pamm,
    funds,
    netProfit,
    tradeResults,
    tradeResults2,
    tradingInterval,
    created
  ) {
    return {
      pamm,
      funds,
      netProfit,
      tradeResults,
      tradeResults2,
      tradingInterval,
      created,
    };
  }

  const rows = [
    createData(
      "#066",
      "$" + numeral(totalAmount).format("0,0.00"),
      "$" +
        numeral(responseData?.netProfit - responseData?.transactionFee).format(
          "0,0.00"
        ),
      "$" + numeral(responseData?.netProfit).format("0,0.00"),
      "$0.00",
      currentMonth.firstDay + " – " + currentMonth.lastDay,
      formatDate(userData?.registration_date)
    ),
  ];

  return (
    <>
      <ResponsiveAppBar2 />
      <div className="dashboard-full">
        <div className="dashboard" style={{ marginBottom: "30px" }}>
          <div
            className="investments-first-div"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <Typography
              className="history-heading"
              style={{ paddingLeft: "0" }}
              variant="h5"
            >
              Investments
            </Typography>
            <Button
              style={{
                color: "#000000de",
                borderColor: "rgb(0 0 0 / 12%)",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                textTransform: "capitalize",
              }}
              variant="outlined"
              onClick={() => navigate("/registerinvestor")}
            >
              <PersonAddAltIcon /> &nbsp;
              <spam style={{ color: "#000000de", fontWeight: "600" }}>
                Register an investor account
              </spam>
            </Button>
          </div>

          <Grid
            item
            xs={1}
            color={"black"}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button></Button>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
              margin: "0 auto",
              marginTop: "20px",
              flexWrap: "wrap",
            }}
          >
            <TextField id="standard-basic" label="Search" variant="standard" />
            {/* <div style={{ display: "flex", alignItems: "center" }}> */}
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label="Show archive"
              style={{ fontSize: "8px !important", marginLeft: "auto" }}
              onClick={handleStartLoading}
            />
            <ReplayIcon
              style={{ cursor: "pointer" }}
              onClick={handleStartLoading}
            />
            {/* </div> */}
          </div>
        </div>

        <div className="table">
          <TableContainer className="transaction-table" component={Paper}>
            {isLoading && <LinearProgress />}
            <Box className="transction-table-inner">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {!isLoading && (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                        >
                          PAMM
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Funds
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Net Profit
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Trade results
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Trade results
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="left"
                        >
                          Trading interval
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Created
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          key={row.pamm}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onClick={() =>
                            navigate("/app/investments/" + userData.id)
                          }
                        >
                          <TableCell component="th" scope="row">
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              PIIPS Capital
                            </span>

                            <span
                              className="table-haeding-font"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              {row.pamm}
                            </span>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ color: "#000", fontWeight: "600" }}
                          >
                            {row.funds}
                          </TableCell>
                          <TableCell
                            className="text-success font-weight-bold"
                            align="right"
                          >
                            {row.netProfit}
                          </TableCell>
                          <TableCell
                            className="text-success font-weight-bold"
                            align="right"
                          >
                            {row.tradeResults}
                          </TableCell>
                          <TableCell
                            //style={{ color: "green", fontWeight: "600" }}
                            align="right"
                          >
                            {row.tradeResults2}
                          </TableCell>
                          <TableCell
                            align="left"
                            className="table-haeding-font"
                            style={{ color: "#000", fontWeight: "600" }}
                          >
                            {row.tradingInterval}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ color: "#000", fontWeight: "600" }}
                          >
                            {row.created}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ color: "#000", fontWeight: "600" }}
                          >
                            {row.closed}
                          </TableCell>
                        </TableRow>
                      ))}
                      <Divider style={{ width: "100%" }} />
                    </TableBody>
                  </>
                )}
              </Table>
            </Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              className="table-pagination"
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ fontSize: "10px" }}
            />
          </TableContainer>
        </div>
      </div>
      <Divider
        style={{ margin: "50px auto", width: "96%", height: "58px !important" }}
      />
    </>
  );
}
