import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"; // Replace with your FontAwesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

import ResponsiveAppBar from "./AppBar2.js";
import ConfirmationBox from "./Config/ConfirmationBox"; // Adjust the path accordingly
import toasterConfig from "./Config/toasterConfig.js";

export default function AccountDetails() {
  library.add(fas);
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const [totalAmount, setTotalAmount] = useState(0);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [accountNumber, setAccountNumber] = useState(userData.account_number);
  const [isAccountNumberExist, setIsAccountNumberExist] = useState(false);

  React.useEffect(() => {
    handleGetTotalAmount();
  }, []);
  const handleGetTotalAmount = () => {
    axios
      .post(API_URL, {
        mood: "getAccountDetails",
        userId: userData.id,
      })
      .then((response) => {
        // console.log(response.data);
        setTotalAmount(response.data.data.totalAmount);
        setIsAccountNumberExist(response.data.data.account_number);
        setAccountNumber(response.data.data.account_number);
      })
      .catch((error) => {
        console.error("Error getting account details:", error);
      });
  };
  const handleChangePassword = () => {
    if (password === "" || newPassword === "") {
      toasterConfig.error("Please fill all fields");
      return;
    }
    axios
      .post(API_URL, {
        mood: "changePassword",
        userId: userData.id,
        password,
        newPassword,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
          toasterConfig.success(response.data.message);
        } else {
          toasterConfig.error(response.data.message);
        }
        // You may want to provide feedback to the user about the password change
      })
      .catch((error) => {
        console.error("Error changing password:", error);
      });
  };

  const handleUpdateAccount = () => {
    if (accountNumber === "") {
      toasterConfig.error("Please insert your wallet ID");
      return;
    }
    axios
      .post(API_URL, {
        mood: "updateAccountNumber",
        userId: userData.id,
        accountNumber,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          toasterConfig.success(response.data.message);
        } else {
          toasterConfig.error(response.data.message);
        }
        handleGetTotalAmount();
        // You may want to provide feedback to the user about the account update
      })
      .catch((error) => {
        console.error("Error updating account:", error);
      });
  };

  const removeWallet = () => {
    axios
      .post(API_URL, {
        mood: "removeWallet",
        userId: userData.id,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          toasterConfig.success(response.data.message);
        } else {
          toasterConfig.error(response.data.message);
        }
        handleGetTotalAmount();
        // You may want to provide feedback to the user about the account update
      })
      .catch((error) => {
        console.error("Error updating account:", error);
      });
  };

  const handleRemoveWallet = () => {
    toast(
      <ConfirmationBox
        message="Are you sure you want to remove this wallet?"
        onConfirm={confirmAction}
        onCancel={cancelAction}
      />,
      {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
      }
    );
  };

  const confirmAction = () => {
    // Perform the removal action
    removeWallet();
    console.log("Wallet removed!");
  };

  const cancelAction = () => {
    // Handle cancellation
    console.log("Action cancelled.");
  };

  return (
    <>
      <ResponsiveAppBar />

      <div
        style={{
          maxWidth: "85%",
          margin: "0 auto",
          marginTop: "0px",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}
      >
        <Card style={{ maxWidth: 400, margin: "auto" }}>
          <CardContent>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "600",
                marginBottom: "0px",
                textTransform: "capitalize",
              }}
              variant="h6"
            >
              {userData.full_name}
            </Typography>

            <Typography
              className="table-haeding-font"
              style={{
                fontSize: "12px",
                fontWeight: "600",
              }}
              variant="h6"
            >
              USD
              <span
                style={{
                  marginLeft: "5px",
                  fontSize: "12px",
                  fontWeight: "",
                }}
              >
                ${totalAmount}
              </span>
            </Typography>
          </CardContent>
        </Card>

        <Card style={{ maxWidth: 400, margin: "auto", marginTop: "20px" }}>
          <CardContent>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "600",
                marginBottom: "0px",
              }}
              variant="h6"
            >
              Receiver Wallet ID (USDT TRC-20)
            </Typography>

            <TextField
              // label=" Wallet ID (USDT TRC-20)"
              fullWidth
              // autoFocus
              margin="normal"
              variant="outlined"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            {isAccountNumberExist && (
              <Stack
                direction="row"
                spacing={4}
                alignItems="flex-end"
                onClick={handleRemoveWallet}
              >
                <FontAwesomeIcon
                  icon={["fa-solid", "fa-circle-xmark"]}
                  style={{ color: "red" }}
                />
              </Stack>
            )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleUpdateAccount}
              style={{
                textSize: "12px",
                marginTop: "15px",
                background: isAccountNumberExist
                  ? "rgb(224, 224, 224)"
                  : " rgb(55, 71, 79)",
                color: isAccountNumberExist ? "black" : "white",
              }}
              disabled={isAccountNumberExist}
            >
              Update Account
            </Button>

            {/* {isAccountNumberExist && (
              <Typography
                variant="body2"
                style={{ color: "red", marginTop: "8px" }}
              >
                Account number can only be updated once
              </Typography>
            )} */}
          </CardContent>
        </Card>

        <Card style={{ maxWidth: 400, margin: "auto", marginTop: "20px" }}>
          <CardContent>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "600",
                marginBottom: "0px",
              }}
              variant="h6"
            >
              Change Password
            </Typography>

            <TextField
              label="Current Password"
              type="password"
              fullWidth
              size="small"
              margin="normal"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              label="New Password"
              type="password"
              size="small"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleChangePassword}
              style={{
                marginTop: "15px",
                background: "rgb(55, 71, 79)",
                fontSize: "12px",
              }}
            >
              Change Password
            </Button>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
