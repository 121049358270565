import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import ResponsiveAppBar from "./AppBar2.js";

export default function AdminInvestorRegistration() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;
  const [formData, setFormData] = React.useState({
    performanceFee: "",
    managementFee: "",
    withdrawalFees: "",
    depositFees: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataWithMood = { ...formData, mood: "settingFees" };

      const response = await axios.post(API_URL, formDataWithMood);
      console.log(response.data); // Handle the server response as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  React.useEffect(() => {
    // Optionally, you can fetch initial data when the component mounts
    const fetchData = async () => {
      try {
        const fetchDataResponse = await axios.post(API_URL, {
          mood: "getSettingFees",
        });
        const fetchedData = fetchDataResponse.data.data;
        // console.log(fetchedData.performance_fee);
        // Update the form data with fetched data
        setFormData({
          ...formData,
          performanceFee: fetchedData.performance_fee,
          managementFee: fetchedData.management_fee,
          withdrawalFees: fetchedData.withdrawal_fees,
          depositFees: fetchedData.deposit_fees,
        });
        // console.log(formData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Run this effect only once when the component mounts

  return (
    <>
      <ResponsiveAppBar />

      <Box>
        <Container maxWidth="md">
          <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
            <Typography variant="h5" gutterBottom>
              Account Settings
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* Performance Fee */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Performance Fee (%)"
                    variant="outlined"
                    fullWidth
                    name="performanceFee"
                    value={formData.performanceFee}
                    onChange={handleChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                  />
                </Grid>

                {/* Management Fee */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Management Fee (%)"
                    variant="outlined"
                    fullWidth
                    name="managementFee"
                    value={formData.managementFee}
                    onChange={handleChange}
                    type="number"
                    autoFocus
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                  />
                </Grid>

                {/* Withdrawal Fees */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Withdrawal Fees"
                    variant="outlined"
                    fullWidth
                    name="withdrawalFees"
                    value={formData.withdrawalFees}
                    onChange={handleChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>

                {/* Deposit Fees */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Deposit Fees"
                    variant="outlined"
                    fullWidth
                    name="depositFees"
                    value={formData.depositFees}
                    onChange={handleChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(55, 71, 79)",
                      color: "#fff",
                    }}
                    type="submit"
                  >
                    Save Settings
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
