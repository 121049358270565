// ConfirmationBox.jsx
import { Button, Typography } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ConfirmationBox = ({ message, onConfirm, onCancel }) => {
  const handleConfirm = () => {
    toast.dismiss();
    onConfirm();
  };

  const handleCancel = () => {
    toast.dismiss();
    onCancel();
  };

  return (
    <div>
      <Typography>{message}</Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleConfirm}
        style={{
          marginTop: "15px",
          background: "rgb(55, 71, 79)",
          fontSize: "12px",
        }}
      >
        Confirm
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleCancel}
        style={{
          marginTop: "15px",
          background: "rgb(55, 71, 79)",
          fontSize: "12px",
        }}
      >
        Cancel
      </Button>
    </div>
  );
};

export default ConfirmationBox;
