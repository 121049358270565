import { IconButton, MenuItem,Menu } from '@mui/material';
import React, { useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
// import { IconButton, Menu, MenuItem } from '@material-ui/core';
// import LanguageIcon from '@material-ui/icons/Language';

export default function Language() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('English'); // Set default language

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    handleMenuClose();
    // You can add logic here to update the language in your application state
  };

  const languages = [
    'English',
    'Deutsch',
    'Español',
    'Français',
    'Italiano',
    'Português',
    '中文 (简体)',
    '日本語',
    '한국어',
    'Русский',
    'العربية',
    'हिन्दी',
    'ไทย',
    'Türkçe',
    'Nederlands',
  ]; // Add more languages as needed

  return (
    <div 
    // style={{display:"flex",justifyContent:"flex-end",alignItems:"center",margin:"0.5rem 2rem"}}
    >
      <IconButton
        color="inherit"
        onClick={handleMenuOpen}
      >
        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 47.5 47.5" viewBox="0 0 47.5 47.5" id="earth"><defs><clipPath id="a"><path d="M0 38h38V0H0v38Z"></path></clipPath></defs><g clipPath="url(#a)" transform="matrix(1.25 0 0 -1.25 0 47.5)"><path fill="#3b88c3" d="M0 0c1.014 1.184 1.902 2.594 2.619 4.198h2.503A16.053 16.053 0 0 0 0 0m-19.837 4.198h2.503c.716-1.604 1.605-3.014 2.619-4.198a16.042 16.042 0 0 0-5.122 4.198m5.122 24.199c-1.014-1.184-1.903-2.594-2.619-4.199h-2.503a16.056 16.056 0 0 0 5.122 4.199m19.837-4.199H2.619C1.902 25.803 1.014 27.213 0 28.397a16.043 16.043 0 0 0 5.122-4.199m-.513-9c-.092 2.506-.522 4.871-1.228 7h3.103a15.868 15.868 0 0 0 2.108-7H4.609zm0-2h3.983a15.872 15.872 0 0 0-2.108-7H3.381c.706 2.129 1.136 4.494 1.228 7m-10.966-7v7h8.968c-.098-2.541-.563-4.917-1.317-7h-7.651zm0-7.919v5.919H.442C-1.194.935-3.61-1.284-6.357-1.721m-2 5.919v-5.919c-2.747.437-5.164 2.656-6.8 5.919h6.8zm-8.969 9h8.969v-7h-7.652c-.754 2.083-1.219 4.459-1.317 7m-1.998 2h-3.983a15.893 15.893 0 0 0 2.107 7h3.105c-.707-2.129-1.136-4.494-1.229-7m10.967 7v-7h-8.969c.098 2.541.563 4.917 1.317 7h7.652zm0 7.92v-5.92h-6.8c1.637 3.264 4.053 5.483 6.8 5.92m9.651-7.92c.754-2.083 1.219-4.459 1.317-7h-8.968v7h7.651zm-7.651 2v5.92c2.747-.437 5.162-2.656 6.798-5.92h-6.798zm-16.95-11h3.983c.092-2.506.522-4.871 1.229-7H-21.2a15.897 15.897 0 0 0-2.107 7m15.95 19c-9.941 0-18-8.059-18-18s8.059-18 18-18 18 8.059 18 18-8.059 18-18 18" transform="translate(26.357 4.802)"></path></g></svg>
        <LanguageIcon style={{color:"#A6A6A6"}}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {languages.map((language) => (
          <MenuItem key={language} onClick={() => handleLanguageSelect(language)}>
            {language}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

