import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import axios from "axios";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toasterConfig from "../Config/toasterConfig.js";
import PageLoader from "../PageLoader.js";
import ResponsiveAppBar from "./AppBar2.js";

export default function AdminPositions() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;
  // State for form fields
  const [positionList, setPositionList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cryptoList, setCryptoList] = React.useState([]);

  const [symbol, setSymbol] = React.useState("");
  const [type, setType] = React.useState("");
  const [lotSize, setLotSize] = React.useState("");
  const [managerVolume, setManagerVolume] = React.useState("");
  const [openPrice, setOpenPrice] = React.useState("");
  const [closePrice, setClosePrice] = React.useState("");
  const [openTime, setOpenTime] = React.useState("");
  const [closeTime, setCloseTime] = React.useState("");
  const [managerProfit, setManagerProfit] = React.useState("");
  const [investorVolume, setInvestorVolume] = React.useState("");
  const [investorProfit, setInvestorProfit] = React.useState("");
  const [openBlockDialog, setOpenPositionDialog] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedPositionId, setSelectedPositionId] = React.useState(null);

  useEffect(() => {
    // Ensure that all required values are present and are valid numbers
    if (
      !isNaN(parseFloat(openPrice)) &&
      !isNaN(parseFloat(closePrice)) &&
      !isNaN(parseFloat(managerVolume)) &&
      !isNaN(parseFloat(lotSize))
    ) {
      // Calculate profit
      const profit =
        (parseFloat(closePrice) - parseFloat(openPrice)) *
        parseFloat(managerVolume) *
        parseFloat(lotSize);
      // Determine profit string based on the type of trade
      if (type === "sell") {
        setManagerProfit(
          profit >= 0 ? "-" + profit.toFixed(2) : Math.abs(profit).toFixed(2)
        );
      } else {
        setManagerProfit(profit.toFixed(2));
      }
    } else {
      // Handle the case when any of the required values is not a valid number
      setManagerProfit("Invalid input");
    }
  }, [openPrice, closePrice, managerVolume, lotSize, type]);

  const handleSubmitConform = async (e) => {
    e.preventDefault();
    setOpenPositionDialog(true);
    // alert("Are you sure you want to submit?");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (
      !symbol ||
      !type ||
      !lotSize ||
      !managerVolume ||
      !openPrice ||
      !closePrice ||
      !openTime ||
      !closeTime ||
      !managerProfit
    ) {
      toasterConfig.error("Please fill in all required fields.");
      return;
    }

    // Disable the submit button during submission
    setSubmitting(true);
    // Create a data object with form values
    const formData = {
      symbol,
      type,
      lotSize,
      managerVolume,
      openPrice,
      closePrice,
      openTime,
      closeTime,
      managerProfit,
      investorVolume,
      investorProfit,
    };

    try {
      // Make a POST request to your API endpoint with Axios
      const response = await axios.post(
        API_URL,
        {
          ...formData,
          mood: "positionAdd",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response as needed
      // console.log("Form submitted successfully:", response);
      if (response.data.success === true) {
        toasterConfig.success(response.data.message);
        formReset();
      } else {
        toasterConfig.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Enable the submit button after submission (whether success or failure)
      setSubmitting(false);
    }
  };

  const formReset = () => {
    fetchPositions();
    setSymbol("");
    setType("");
    setLotSize("");
    setManagerVolume("");
    setOpenPrice("");
    setClosePrice("");
    setOpenTime("");
    setCloseTime("");
    setManagerProfit("");
    setInvestorVolume("");
    setInvestorProfit("");
    setOpenPositionDialog(false);
  };

  useEffect(() => {
    setTimeout(() => {
      fetchSymbols();
      fetchPositions();
    }, 2000);
    // You can replace this with actual API call to PHP backend
    // Example: fetchCryptoDataFromBackend().then(data => setCryptoList(data));
  }, []);

  const fetchSymbols = async () => {
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "listSymbols",
      });
      if (response.data.success === true) {
        setCryptoList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  const fetchPositions = async () => {
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "listPositions",
      });
      if (response.data.success === true) {
        setPositionList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading
    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleMenuItemClick = (event) => {
    const symbolId = event.currentTarget.getAttribute("data-symbol-id");
    // console.log("Symbol ID:", symbolId);
    setLotSize(symbolId);
    // Do whatever you need with the symbolId
    // ...
  };

  const formatNumber = (number, decimalPlaces = 5) => {
    // return Number(number).toFixed(decimalPlaces);
    const formattedNumber = parseFloat(number).toFixed(decimalPlaces);
    return formattedNumber.endsWith(".00")
      ? formattedNumber.slice(0, -3) // Remove trailing '.00'
      : formattedNumber;
  };

  const handleDelete = async () => {
    // Perform deletion logic here (e.g., make a DELETE request to your backend)
    // After successful deletion, update the positionList state to reflect the changes
    console.log(`Deleting position with ID: ${selectedPositionId}`);
    // Example: You can remove the deleted item from the positionList array
    // const updatedPositionList = positionList.filter(row => row.id !== selectedPositionId);
    // setPositionList(updatedPositionList);

    // You can also fetch the updated list from the backend
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "deletePosition",
        positionId: selectedPositionId,
      });
      if (response.data.success === true) {
        setOpen(false);
        toasterConfig.success(response.data.message);
        fetchPositions();
      }
    } catch (error) {
      console.error("Error deleting position:", error);
      toasterConfig.error("Error deleting position");
    }
  };

  const handleClickOpen = (positionId) => {
    setSelectedPositionId(positionId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleDateChange = (date) => {
  //   setCreatedAt(date);
  // };
  return (
    <>
      <ResponsiveAppBar />
      <ToastContainer />

      <div
        className="dashboard history-dashboard"
        style={{ maxWidth: "90%", margin: "0 auto" }}
      >
        <Typography className="history-heading" variant="h5">
          Positions
        </Typography>
        <div className="table history">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading(true)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  style={{
                    maxWidth: "100%",
                  }}
                  spacing={2}
                >
                  <Grid item lg={4} md={6} sm={12}>
                    <Box
                      className="symbol-add"
                      style={{
                        padding: "0 !important",
                      }}
                    >
                      <Card
                        style={{
                          maxWidth: "100%",
                        }}
                        sx={{ maxWidth: 345 }}
                      >
                        {/* <CardHeader
                          className="symbol-header"
                          title="Position Added"
                        /> */}
                        <CardHeader
                          className="symbol-header"
                          title={
                            <Typography variant="h4" fontWeight="bold">
                              Position Added
                            </Typography>
                          }
                        />

                        <CardContent>
                          <Box component="form" onSubmit={handleSubmitConform}>
                            <Paper
                              elevation={3}
                              style={{
                                padding: "20px",
                                maxWidth: "500px",
                                margin: "auto",
                              }}
                            >
                              <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                              >
                                Position Form
                              </Typography>

                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel id="symbol-label">
                                      Symbol
                                    </InputLabel>
                                    <Select
                                      labelId="symbol-label"
                                      id="symbol"
                                      value={symbol}
                                      label="Symbol"
                                      onChange={(e) =>
                                        setSymbol(e.target.value)
                                      }
                                    >
                                      {cryptoList.map((symbol) => (
                                        <MenuItem
                                          key={symbol.id}
                                          value={symbol.symbol}
                                          data-symbol-id={symbol.volume} // Add the data attribute here
                                          onClick={handleMenuItemClick} // Add an onClick event handler
                                        >
                                          {symbol.symbol}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Lot Size"
                                    type="number"
                                    value={lotSize}
                                    autoFocus
                                    readOnly={true}
                                    disabled={true}
                                    InputProps={{
                                      inputProps: {
                                        style: { color: "#000" }, // Set your desired placeholder color
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel htmlFor="type">Type</InputLabel>
                                    <Select
                                      label="Type"
                                      name="type"
                                      value={type}
                                      onChange={(e) => setType(e.target.value)}
                                    >
                                      <MenuItem value="sell">Sell</MenuItem>
                                      <MenuItem value="buy">Buy</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Manager Volume"
                                    type="number"
                                    name="managerVolume"
                                    value={managerVolume}
                                    onChange={(e) =>
                                      setManagerVolume(e.target.value)
                                    }
                                    autoFocus
                                    InputProps={{
                                      inputProps: {
                                        style: { color: "#000" }, // Set your desired placeholder color
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    autoFocus
                                    fullWidth
                                    label="Open Price"
                                    type="number"
                                    name="openPrice"
                                    value={openPrice}
                                    onChange={(e) =>
                                      setOpenPrice(e.target.value)
                                    }
                                    InputProps={{
                                      inputProps: {
                                        style: { color: "#000" }, // Set your desired placeholder color
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    autoFocus
                                    fullWidth
                                    label="Close Price"
                                    type="number"
                                    name="closePrice"
                                    value={closePrice}
                                    onChange={(e) =>
                                      setClosePrice(e.target.value)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DateTimePicker"]}
                                    >
                                      <DateTimePicker
                                        label="Open Time"
                                        value={openTime}
                                        onChange={(newValue) =>
                                          setOpenTime(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{ step: 300 }}
                                          />
                                        )}
                                        format="MM/DD/YYYY HH:mm:ss"
                                        ampm={false}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DateTimePicker"]}
                                    >
                                      <DateTimePicker
                                        label="Close Time"
                                        value={closeTime}
                                        onChange={(newValue) =>
                                          setCloseTime(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{ step: 300 }}
                                          />
                                        )}
                                        format="MM/DD/YYYY HH:mm:ss"
                                        ampm={false}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    autoFocus
                                    fullWidth
                                    label="Manager Profit"
                                    type="number"
                                    name="managerProfit"
                                    readOnly={true}
                                    disabled={true}
                                    value={managerProfit}
                                    onChange={(e) =>
                                      setManagerProfit(e.target.value)
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    style={{
                                      marginTop: "15px",
                                      background: "rgb(55, 71, 79)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Box>
                        </CardContent>
                        <CardActions disableSpacing></CardActions>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid style={{ width: "100%" }} item lg={8} md={6} sm={12}>
                    <Box>
                      <TableContainer
                        className="symble-table-outer"
                        component={Paper}
                        style={{ overflow: "auto" }}
                      >
                        <Table
                          sx={{ minWidth: 460 }}
                          className="symbol-add"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Position Type</TableCell>
                              <TableCell>Symbol</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>lot_size</TableCell>
                              <TableCell>Manager Volume</TableCell>
                              <TableCell>Open Price</TableCell>
                              <TableCell>Close Price</TableCell>
                              <TableCell>Manager Profit</TableCell>
                              <TableCell>Created at</TableCell>
                              <TableCell>Action</TableCell>{" "}
                              {/* Add this column for delete action */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {positionList.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.position_type}</TableCell>
                                <TableCell>{row.symbol}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.lot_size}</TableCell>
                                <TableCell>
                                  {formatNumber(row.manager_volume)}
                                </TableCell>
                                <TableCell>
                                  {formatNumber(row.open_price)}
                                </TableCell>
                                <TableCell>
                                  {formatNumber(row.close_price)}
                                </TableCell>
                                <TableCell>
                                  {formatNumber(row.manager_profit)}
                                </TableCell>
                                <TableCell>{row.created_at}</TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => handleClickOpen(row.id)}
                                    color="secondary"
                                    variant="outlined"
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </div>
      </div>

      {/* Position Confirmation Dialog */}
      <Dialog
        open={openBlockDialog}
        onClose={() => setOpenPositionDialog(false)}
      >
        <Box>
          <DialogTitle className="font-weight-bold">
            Position Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="font-size-150">
              Are you sure you want to store Position?
            </DialogContentText>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenPositionDialog(false)}
            color="primary"
            style={{
              marginTop: "15px",
              background: "rgb(55, 71, 79)",
              fontSize: "12px",
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            style={{
              marginTop: "15px",
              background: "rgb(55, 71, 79)",
              fontSize: "12px",
            }}
            disabled={submitting} // Disable the button if submitting
          >
            {submitting ? (
              <>
                <span>Saving...</span>
                <CircularProgress size={16} style={{ marginLeft: "8px" }} />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this position?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
