import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import { LinearProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export default function OpenPosition() {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading

    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  function createData(
    id,
    reason,
    manager,
    investment,
    amount,
    time,
    comment,
    request,
    rolloverID,
    positionID
  ) {
    return {
      id,
      reason,
      manager,
      investment,
      amount,
      time,
      comment,
      request,
      rolloverID,
      positionID,
    };
  }

  const rows = [createData("", "", "", "", "", "", "", "", "", "")];

  return (
    <div>
      <>
        <div className="tableTop"></div>

        <div className="table history">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading()}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <TableContainer component={Paper}>
            {isLoading && <LinearProgress />}
            {!isLoading && (
              <>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontSize: "12px" }}>#</TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="center">
                        Symbol
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="right">
                        Type
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="right">
                        Manager Volume
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="right">
                        Open Price
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="left">
                        Close Price
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="right">
                        Open Time
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="right">
                        Manager Profit
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="right">
                        Investor Volume
                      </TableCell>
                      <TableCell style={{ fontSize: "12px" }} align="right">
                        Investor Profit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ fontSize: "12px" }}
                          component="th"
                          scope="row"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="right">{row.reason}</TableCell>
                        <TableCell align="right">{row.manager}</TableCell>
                        <TableCell align="right">{row.investment}</TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                        <TableCell align="left">{row.time}</TableCell>
                        <TableCell align="right">{row.comment}</TableCell>
                        <TableCell align="right">{row.request}</TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          {row.rolloverID}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </TableContainer>
        </div>
      </>
    </div>
  );
}
