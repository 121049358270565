import Button from "@mui/material/Button";
import React, { useState } from "react";

import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

import { ButtonGroup } from "@mui/material";
import ClosePosition from "./ClosePosition";
import OpenPosition from "./Position";

export default function PositionDetails() {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = React.useState("transactions");

  const handleLogin = () => {
    navigate("/home");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const [server, setServer] = React.useState("Vantage International Live 11");
  const [mtAccount, setMtAccount] = React.useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setServer(event.target.value);
  };

  const handleChange2 = (event) => {
    const inputValue = event.target.value;
    setMtAccount(inputValue);
    setError(inputValue.trim() === "");
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange3 = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { gilad, jason, antoine } = state;
  const error1 = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const useStyles = styled((theme) => ({
    customContainer: {
      [theme.breakpoints.up("xs")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
    },
  }));
  return (
    <>
      <div className="join-parent">
        <Container
          className="main-container"
          component="main"
          style={{ width: "100%", padding: 0, margin: "0 0" }}
        >
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              style={{
                padding: ".5rem",
                color: activeButton === "transactions" ? "black" : "black",
                backgroundColor:
                  activeButton === "transactions" ? "#D7D7D7" : "white",
                borderColor: "#D7D7D7",
                textTransform: "capitalize",
                fontSize: "13px",
              }}
              onClick={() => setActiveButton("transactions")}
            >
              Open Positions
            </Button>
            <Button
              style={{
                color: activeButton === "requests" ? "black" : "black",
                backgroundColor:
                  activeButton === "requests" ? "#D7D7D7" : "transparent",
                borderColor: "#D7D7D7",
                textTransform: "capitalize",
                fontSize: "13px",
              }}
              onClick={() => setActiveButton("requests")}
            >
              Close Positions
            </Button>
          </ButtonGroup>

          {activeButton === "transactions" && <OpenPosition />}
          {activeButton === "requests" && <ClosePosition />}
        </Container>
      </div>
    </>
  );
}
