import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toasterConfig from "../Config/toasterConfig.js";
import PageLoader from "../PageLoader.js";
import ResponsiveAppBar from "./AppBar2.js";

export default function PerformanceFee() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;
  // State for form fields
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [positionList, setPositionList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [openBlockDialog, setOpenPositionDialog] = React.useState(false);
  const [performanceFee, setPerformanceFee] = React.useState("100");
  const [openTime, setOpenTime] = React.useState("");
  const [closeTime, setCloseTime] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a data object with form values
    const formData = {
      performanceFee,
      closeTime,
      openTime,
    };

    try {
      // Make a POST request to your API endpoint with Axios
      const response = await axios.post(
        API_URL,
        {
          ...formData,
          mood: "performanceFeeAdd",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response as needed
      console.log("Form submitted successfully:", response);
      if (response.data.success === true) {
        toasterConfig.success(response.data.message);
        setOpenPositionDialog(false);
        fetchPositions();
      } else {
        toasterConfig.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchPositions(page, rowsPerPage);
    }, 2000);
  }, []);

  const fetchPositions = async (page, rowsPerPage) => {
    setPositionList([]);
    try {
      const response = await axios.post(`${API_URL}`, {
        mood: "performanceFeeList",
      });
      if (response.data.success === true) {
        setPositionList(response.data.data);
        setTotalRows(parseInt(response.data.totalRows ?? 0));
      }
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading
    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    fetchPositions(page, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPage(1);
    setRowsPerPage(newRowsPerPage);

    fetchPositions(1, newRowsPerPage);
  };

  const handleSubmitConform = async (e) => {
    e.preventDefault();
    setOpenPositionDialog(true);
    // alert("Are you sure you want to submit?");
  };

  const formatNumber = (number, decimalPlaces = 5) => {
    // return Number(number).toFixed(decimalPlaces);
    const formattedNumber = parseFloat(number).toFixed(decimalPlaces);
    return formattedNumber.endsWith(".00")
      ? formattedNumber.slice(0, -3) // Remove trailing '.00'
      : formattedNumber;
  };
  // const handleDateChange = (date) => {
  //   setCreatedAt(date);
  // };
  return (
    <>
      <ResponsiveAppBar />
      <ToastContainer />

      <div
        className="dashboard history-dashboard"
        style={{ maxWidth: "90%", margin: "0 auto" }}
      >
        <Typography className="history-heading" variant="h5">
          Performance Fee
        </Typography>
        <div className="table history">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading(true)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  style={{
                    maxWidth: "100%",
                  }}
                  spacing={2}
                >
                  <Grid item lg={4} md={6} sm={12}>
                    <Box
                      className="symbol-add"
                      style={{
                        padding: "0 !important",
                      }}
                    >
                      <Card
                        style={{
                          maxWidth: "100%",
                        }}
                        sx={{ maxWidth: 345 }}
                      >
                        <CardHeader
                          className="symbol-header"
                          title={
                            <Typography variant="h4" fontWeight="bold">
                              Add Performance Fee
                            </Typography>
                          }
                        />

                        <CardContent>
                          <Box component="form" onSubmit={handleSubmitConform}>
                            <Paper
                              elevation={3}
                              style={{
                                padding: "20px",
                                maxWidth: "500px",
                                margin: "auto",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Fees %"
                                    type="number"
                                    value={performanceFee}
                                    onChange={(event) =>
                                      setPerformanceFee(event.target.value)
                                    }
                                    autoFocus
                                    InputProps={{
                                      inputProps: {
                                        style: { color: "#000" }, // Set your desired placeholder color
                                      },
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DateTimePicker"]}
                                    >
                                      <DateTimePicker
                                        label="Form Date"
                                        value={openTime}
                                        onChange={(newValue) =>
                                          setOpenTime(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{ step: 300 }}
                                          />
                                        )}
                                        ampm={false}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DateTimePicker"]}
                                    >
                                      <DateTimePicker
                                        label="To Date"
                                        value={openTime}
                                        onChange={(newValue) =>
                                          setCloseTime(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{ step: 300 }}
                                          />
                                        )}
                                        ampm={false}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12}>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    style={{
                                      marginTop: "15px",
                                      background: "rgb(55, 71, 79)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Box>
                        </CardContent>
                        <CardActions disableSpacing></CardActions>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid style={{ width: "100%" }} item lg={8} md={6} sm={12}>
                    <TableContainer
                      component={Paper}
                      style={{ overflow: "hidden" }}
                    >
                      <Box className="symbol-add">
                        <Table sx={{ minWidth: 460 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Performance Fee</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>To Date</TableCell>
                              <TableCell>Form Date</TableCell>
                              <TableCell>Created at</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {positionList.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.percentage}</TableCell>
                                <TableCell>{row.profit}</TableCell>
                                <TableCell>{row.start_date}</TableCell>
                                <TableCell>{row.end_date}</TableCell>
                                <TableCell>{row.datetime}</TableCell>
                                <TableCell>
                                  <Link
                                    to={`/app/admin/performance-fees/${row.id}`}
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        background: "rgb(55, 71, 79)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      View
                                    </Button>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                      <TablePagination
                        className="table-pagination"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </div>
      </div>

      {/* Position Confirmation Dialog */}
      <Dialog
        open={openBlockDialog}
        onClose={() => setOpenPositionDialog(false)}
      >
        <Box>
          <DialogTitle className="font-weight-bold">
            Position Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="font-size-150">
              Are you sure you want to store Position?
            </DialogContentText>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenPositionDialog(false)}
            color="primary"
            style={{
              marginTop: "15px",
              background: "rgb(55, 71, 79)",
              fontSize: "12px",
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            style={{
              marginTop: "15px",
              background: "rgb(55, 71, 79)",
              fontSize: "12px",
            }}
            disabled={submitting} // Disable the button if submitting
          >
            {submitting ? (
              <>
                <span>Saving...</span>
                <CircularProgress size={16} style={{ marginLeft: "8px" }} />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
