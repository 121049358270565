import { toast } from "react-toastify";

const toasterConfig = {
  error: (message) => {
    toast.error(
      <div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "600",
            margin: "0 0 12px 0",
          }}
        >
          {message}
        </p>
      </div>,
      {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      }
    );
  },
  success: (message) => {
    toast.success(
      <div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "600",
            margin: "0 0 12px 0",
          }}
        >
          {message}
        </p>
      </div>,
      {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
      }
    );
  },
  // Add more configurations as needed
};

export default toasterConfig;
