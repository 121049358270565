import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import React from "react";
import { useNavigate } from "react-router-dom";

import Language from "./Languages";
import "./appAuth.css";
export default function LogIn() {
  React.useState(() => {
    document.title = "PAMM - Taurex";
  }, []);

  const navigate = useNavigate();

  // const handleInvestorButtonClick = () => {
  //   navigate("/app/auth/investor");
  // };

  // const handleMoneyManagerButtonClick = () => {
  //   navigate("/app/auth/money-manager"); // Update this with the desired route
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "0.7rem 2rem",
        }}
      >
        <Language />
      </div>
      <Container className="authContainer" component="main" maxWidth="xs">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src="/logo.png" alt="logo" style={{ width: "300px", height: "50px", marginTop: "2rem" }} /> */}
          <img
            src="/assets/taurex_logo.png"
            alt="logo"
            style={{ width: "170px", height: "auto", marginTop: "2rem" }}
          />
        </div>
        <p
          className="login-heading"
          style={{
            margin: "auto",
            marginTop: "30px",
            padding: "0 5px",
            textalign: "center",
          }}
        >
          <span
            style={{
              font: "400 22px/32px Nunito,sans-serif",
              color: "rgb(0 0 0 / 87%)",
              fontWeight: "600",
              fontsize: "22px",
            }}
          >
            PAMM - Taurex
          </span>
          <span
            style={{
              opacity: ".5",
              paddingLeft: "10px",
              color: "#6c757d",
              fontWeight: "700",
            }}
          >
            Investor
          </span>
        </p>
        <p
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            fontSize: "small",
            opacity: "0.8",
          }}
        ></p>
        <Box
          className="authBox"
          sx={{
            maxWidth: "33%",
            margin: "auto",
            boxShadow:
              "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)",
            borderRadius: 1,
            px: 2,
            py: 2,
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            paddingTop: "0",
          }}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "left",

              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <p
              style={{
                display: "flex",
                fontSize: "small",
                paddingLeft: "19px",
                marginBottom: "0",
                color: "rgb(0 0 0 / 54%)",
                fontWeight: "600",
              }}
            >
              Account
            </p>

            <Button
              variant="text"
              style={{
                width: "100%",
                height: "56px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "0 1rem",
                color: "rgb(0 0 0 / 87%)",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              onClick={() => navigate("/app/auth/investor")}
            >
              {/* <PeopleOutlineIcon
                style={{
                  color: "black",
                  fontSize: "30px",
                  marginRight: "10px",
                }}
              /> */}
              <img
                src="/assets/investor_ico.png"
                alt="logo"
                style={{ width: "26px", height: "22px", marginRight: "5px" }}
              />
              Investor
            </Button>

            <Button
              variant="text"
              style={{
                width: "100%",
                height: "56px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "0 1rem",
                color: "rgb(0 0 0 / 87%)",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              onClick={() => navigate("/app/auth/manager")}
            >
              <img
                src="/assets/manager_ico.png"
                alt="logo"
                style={{ width: "26px", height: "22px", marginRight: "5px" }}
              />
              Money Manager
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
