import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, LinearProgress } from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import * as React from "react";

export default function Requests() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [data, setData] = React.useState([]);

  // useEffect to fetch data when the component mounts
  React.useEffect(() => {
    // Define the API endpoint
    fetchData(page, rowsPerPage);
  }, []);

  const fetchData = (page, rowsPerPage) => {
    axios
      .post(API_URL, {
        mood: "request",
        userId: userData.id,
        page: page,
        rowsPerPage: rowsPerPage,
      })
      .then((response) => {
        setData(response.data.data);
        // console.log(parseInt(response.data.totalRows));
        setTotalRows(parseInt(response.data.totalRows));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading

    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleChangePage = (event, newPage) => {
    var pages = newPage + 1;
    setPage(pages);
    fetchData(pages, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPage(1);
    setRowsPerPage(newRowsPerPage);

    fetchData(1, newRowsPerPage);
  };

  return (
    <div>
      <>
        <div className="tableTop"></div>

        <div className="table history">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading()}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <TableContainer className="transaction-table" component={Paper}>
            {isLoading && <LinearProgress />}
            {!isLoading && (
              <>
                <Box className="transction-table-inner">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="center"
                        >
                          Type
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Manager
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Investment
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="left"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          External ID
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Comment
                        </TableCell>
                        <TableCell
                          className="table-haeding-font"
                          style={{ fontSize: "12px" }}
                          align="right"
                        >
                          Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            className="table-haeding-font"
                            style={{ fontSize: "12px" }}
                            component="th"
                            scope="row"
                          >
                            #{row.id}
                          </TableCell>
                          <TableCell className="history-font" align="right">
                            {row.requestType}
                          </TableCell>
                          {/* <TableCell align="right">{row.reason}</TableCell> */}
                          <TableCell className="history-font" align="right">
                            {row.managerName}{" "}
                            <span className="table-haeding-font">#066</span>
                          </TableCell>
                          <TableCell className="history-font" align="right">
                            {row.investorName}
                            <span className="table-haeding-font">
                              #{row.investmentId}
                            </span>
                          </TableCell>
                          <TableCell className="history-font" align="right">
                            ${row.amount}
                          </TableCell>
                          <TableCell className="history-font" align="left">
                            {row.status}
                          </TableCell>
                          <TableCell className="history-font" align="right">
                            {row.external_id}
                          </TableCell>
                          <TableCell
                            className="table-haeding-font"
                            align="right"
                          >
                            {row.comment}
                          </TableCell>
                          <TableCell className="history-font" align="right">
                            {row.time}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </TableContainer>
        </div>
      </>
    </div>
  );
}
