import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import axios from "axios";
import numeral from "numeral";
import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../PageLoader.js";
import ResponsiveAppBar from "./AppBar2.js";

export default function DemoPosition() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [deleteUserId, setDeleteUserId] = React.useState(null);
  const [blockUserId, setBlockUserId] = React.useState(null);
  const [blockUserStatus, setBlockUserStatus] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openBlockDialog, setOpenBlockDialog] = React.useState(false);

  const handleStartLoading = () => {
    setIsLoading(true);

    // Your asynchronous operation or any code that triggers loading
    // Simulate a delay (remove this line in a real application)
    setTimeout(() => {
      setIsLoading(false);
      fetchData(page, rowsPerPage);
    }, 2000);
  };

  const fetchData = async (page, rowsPerPage) => {
    try {
      const response = await axios.post(API_URL, {
        mood: "adminPosition",
        page: page,
        rowsPerPage: rowsPerPage,
      });
      // Set the fetched data to the state
      setData(response.data.data ?? []);
      setTotalRows(response.data.totalRows ?? 0);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    handleStartLoading();
  }, []);

  const handleChangePage = (event, newPage) => {
    var pages = newPage + 1;
    setPage(pages);
    fetchData(pages, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPage(1);
    setRowsPerPage(newRowsPerPage);

    fetchData(1, newRowsPerPage);
  };

  const formatNumber = (number) => {
    const roundedNumber = parseFloat(number).toFixed(4); // Always format with two digits after the decimal point
    const originalValue = parseFloat(number); // Convert to a floating-point number to remove trailing zeros

    return originalValue % 1 === 0 ? originalValue.toFixed(0) : roundedNumber;
  };

  return (
    <>
      <ResponsiveAppBar />
      <ToastContainer />
      <div
        className="dashboard history-dashboard"
        style={{
          maxWidth: "90%",
          margin: "0 auto",
        }}
      >
        <Typography className="history-heading" variant="h5">
          User
        </Typography>

        <div className="table history">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}
          >
            <FilterAltIcon style={{ cursor: "pointer" }} />
            <div>
              <FileDownloadIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
              <ReplayIcon
                onClick={() => handleStartLoading(true)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <TableContainer className="transaction-table" component={Paper}>
            {isLoading ? (
              <PageLoader />
            ) : (
              <>
                <Box className="transction-table-inner">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: "12px" }}>#</TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="center">
                          Symbol
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          Type
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          Manager Volume
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          Open Price
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="left">
                          Close Price
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          Open Time
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          Close Time
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          Manager Profit
                        </TableCell>
                        <TableCell style={{ fontSize: "12px" }} align="right">
                          Investor Profit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right" className="history-font">
                            {row.id}
                          </TableCell>
                          <TableCell align="right" className="history-font">
                            {row.symbol}
                          </TableCell>
                          <TableCell align="right" className="history-font">
                            {row.type}
                          </TableCell>
                          <TableCell align="right" className="history-font">
                            {formatNumber(row.managerVolume)}
                          </TableCell>
                          <TableCell align="right" className="history-font">
                            {formatNumber(row.openPrice)}
                          </TableCell>
                          <TableCell align="right" className="history-font">
                            {formatNumber(row.closePrice)}
                          </TableCell>
                          <TableCell align="right" className="history-font">
                            {row.openTime}
                          </TableCell>
                          <TableCell align="left" className="history-font">
                            {row.closeTime}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "600",
                              color:
                                row.managerProfit < 0 ? "#dc3545" : "#28a745",
                            }}
                          >
                            {row.managerProfit > 0
                              ? "$" +
                                numeral(row.managerProfit).format("0,0.00")
                              : "-$" +
                                numeral(Math.abs(row.managerProfit)).format(
                                  "0,0.00"
                                )}
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "600",
                              color:
                                row.investorProfit < 0 ? "#dc3545" : "#28a745",
                            }}
                            align="right"
                          >
                            {row.investorProfit > 0
                              ? "$" +
                                numeral(row.investorProfit).format("0,0.00")
                              : "-$" +
                                numeral(Math.abs(row.investorProfit)).format(
                                  "0,0.00"
                                )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
}
