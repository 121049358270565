import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useLocation } from "react-router-dom";
import ResponsiveAppBar from "./AppBar2.js";
import Requests from "./Requests.js";
import Transactions from "./Transactions.js";

export default function History() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");
  // console.log(query);
  const [activeButton, setActiveButton] = React.useState("transactions");
  React.useEffect(() => {
    // console.log(query);
    if (query === "requests") {
      setActiveButton("requests");
    }
  }, [query]);
  //
  // query === "requests" ? setActiveButton("requests") : "transactions";

  return (
    <>
      <ResponsiveAppBar />

      <div
        className="dashboard history-dashboard"
        style={{
          maxWidth: "90%",
          margin: "0 auto",
        }}
      >
        <Typography className="history-heading" variant="h5">
          History
        </Typography>

        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            style={{
              padding: "14px 12px",
              color:
                activeButton === "transactions" ? "black" : "black !important",
              backgroundColor:
                activeButton === "transactions" ? "#D7D7D7" : "white",
              borderColor: "#D7D7D7",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            onClick={() => setActiveButton("transactions")}
          >
            Transactions
          </Button>
          <Button
            style={{
              padding: "14px 12px",
              color: activeButton === "requests" ? "black" : "black",
              backgroundColor:
                activeButton === "requests" ? "#D7D7D7" : "transparent",
              borderColor: "#D7D7D7",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            onClick={() => setActiveButton("requests")}
          >
            Requests
          </Button>
        </ButtonGroup>

        {activeButton === "transactions" && <Transactions />}
        {activeButton === "requests" && <Requests />}
      </div>
    </>
  );
}
